import React from "react";

import { Accordion } from "react-bootstrap";

import "./AccordionStandCard.css";

const AccordionStandCard = (props) => {
    return (
        <div>
            <Accordion
                className={`accordion-stand-card accordion-stand-card--${
                    props.preview ? "preview" : "grid"
                }`}
                flush
            >
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <h3
                            className="accordion-stand-card__title--yellow"
                            style={{ color: props.hexColor }}
                        >
                            {props.firstTitle}
                        </h3>
                    </Accordion.Header>
                    <Accordion.Body>
                        {props.description && (
                            <div className="accordion-stand-card__description">
                                {props.description}
                            </div>
                        )}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
           
        </div>
    );
};

export default AccordionStandCard;
