import React from "react";

import { FormInputComponent } from "../../../../shared/components/FormElements/GenericForm";

const EcommerceStandCardEdition = (props) => {
    return (
        <React.Fragment>
            <FormInputComponent
                fieldType="text"
                fieldName={`cards.${props.index}.cardTextColor`}
                fieldDescription={
                    "Color de texto: El color de texto elegido aplica para la descripción y el segundo título en la card."
                }
                colorPicker={true}
                colorPickerHandler={() =>
                    props.showHexPickerHandler(
                        `values.cards.${props.index}.cardTextColor`,
                        `cards.${props.index}.cardTextColor`
                    )
                }
                fieldPlaceholder="#000000"
            />
            <FormInputComponent
                fieldType="file"
                fieldName={`cards.${props.index}.image`}
                fieldDescription="Imagen"
                fieldPlaceholder=""
                fileUpload={true}
                setFieldValue={props.setFieldValue}
            />
            <div>
                <FormInputComponent
                    fieldType="number"
                    fieldName={`cards.${props.index}.price`}
                    fieldDescription="Precio"
                    fieldPlaceholder=""
                />
                <FormInputComponent
                    fieldType="number"
                    fieldName={`cards.${props.index}.pricediscount`}
                    fieldDescription="Precio con descuento"
                    fieldPlaceholder=""
                />
            </div>
            <FormInputComponent
                fieldType="text"
                fieldName={`cards.${props.index}.description`}
                fieldDescription="Descripción"
                fieldPlaceholder=""
            />
            <FormInputComponent
                fieldType="select"
                fieldAs="select"
                fieldSelect={[
                    {
                        value: "",
                        text: "Seleccione Opción",
                    },
                    {
                        value: "None",
                        text: "Sin Botón",
                    },
                    {
                        value: "Action",
                        text: "Botón con call to action",
                    },
                    {
                        value: "Url",
                        text: "Botón a url",
                    },
                ]}
                fieldName={`cards.${props.index}.buttonType`}
                fieldDescription={`Tipo de Botón`}
                fieldPlaceholder=""
                onChange={(e) => {
                    props.setFieldValue(
                        `cards.${props.index}.buttonType`,
                        e.target.value
                    );
                }}
            />
            {props.card.buttonType === "Action" && (
                <div className="card-edition-form__input-block--grid">
                    <div className="card-edition-form__input-block--column">
                        <FormInputComponent
                            fieldType="text"
                            fieldName={`cards.${props.index}.buttonText`}
                            fieldDescription="Texto de botón"
                            fieldPlaceholder=""
                        />
                        <FormInputComponent
                            fieldType="select"
                            fieldAs="select"
                            fieldSelect={[
                                {
                                    value: 0,
                                    text: "Seleccione",
                                },
                                {
                                    value: "text",
                                    text: "Texto",
                                },
                                {
                                    value: "select",
                                    text: "Desplegable con opciones",
                                },
                            ]}
                            fieldName={`cards.${props.index}.formInformation.fieldAs`}
                            fieldDescription={`Tipo de Campo`}
                            fieldPlaceholder=""
                            className={"card-edition-form__input-block--taller"}
                        />
                    </div>
                    <div className="card-edition-form__input-block--column">
                        <FormInputComponent
                            fieldType="text"
                            fieldName={`cards.${props.index}.formInformation.fieldDescription`}
                            fieldDescription="Descripción del campo de formulario"
                            fieldPlaceholder="Escriba su respuesta..."
                        />
                        <FormInputComponent
                            fieldType="text"
                            fieldName={`cards.${props.index}.formInformation.fieldSelect`}
                            fieldDescription="Insertar valores separados por punto y coma, empezando con el título. (Solo en caso de utilizar desplegable con opciones)"
                            fieldPlaceholder="Ejemplo: Textil;Comercial;Ganadero"
                            className={"card-edition-form__input-block--taller"}
                        />
                    </div>
                </div>
            )}
            {props.card.buttonType === "Url" && (
                <div className="card-edition-form__input-block--grid">
                    <div className="card-edition-form__input-block--column">
                        <FormInputComponent
                            fieldType="text"
                            fieldName={`cards.${props.index}.buttonText`}
                            fieldDescription="Texto de botón"
                            fieldPlaceholder=""
                        />
                    </div>

                    <div className="card-edition-form__input-block--column">
                        <FormInputComponent
                            fieldType="text"
                            fieldName={`cards.${props.index}.buttonLink`}
                            fieldDescription="Link de botón (Sin incluir https://)"
                            fieldPlaceholder=""
                        />
                    </div>
                </div>
            )}
            <FormInputComponent
                fieldType="select"
                fieldAs="select"
                fieldSelect={[
                    {
                        value: true,
                        text: "Si",
                    },
                    {
                        value: false,
                        text: "No",
                    },
                ]}
                fieldName={`cards.${props.index}.interestedButton`}
                fieldDescription={`Botón de "Me Interesa"`}
                fieldPlaceholder=""
            />
        </React.Fragment>
    );
};

export default EcommerceStandCardEdition;
