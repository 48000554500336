import React from "react";
import { Link } from "react-router-dom";

import "./Button.css";

const Button = (props) => {
    if (props.href) {
        return (
            <a
                className={`main-button ${props.className} main-button--${props.status}`}
                target={props.target}
                href={props.href}
                style={props.style}
            >
                {props.children}
            </a>
        );
    }
    if (props.to) {
        return (
            <Link
                to={props.to}
                exact={props.exact}
                className={`main-button ${props.className} main-button--${props.status}`}
                style={props.style}
            >
                {props.children}
            </Link>
        );
    }
    return (
        <button
            className={`main-button ${props.className} main-button--${props.status}`}
            type={props.type}
            onClick={props.onClick}
            style={props.style}
            disabled={props.disabled}
        >
            <div>{props.children}</div>
        </button>
    );
};

export default Button;
