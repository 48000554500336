import React, { useState, useEffect, useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/AuthContext";
import Button from "../../../shared/components/FormElements/Button";
import BackButton from "../../../shared/components/UIElements/BackButton";
import BannerStands from '../assets/Banner5.png'; 
import { BsFillHeartFill , BsFileEarmarkPerson , BsFillTagFill , BsFillEyeFill , BsPencil , BsFileBarGraphFill , BsFillDiamondFill , BsFillFileCodeFill } from "react-icons/bs";


import "./StandsList.css";

const StandsList = () => {
    const auth = useContext(AuthContext);
    const [loadedStands, setLoadedStands] = useState();
    const { isLoading, sendRequest } = useHttpClient();
    const [query,setQuery]=useState("");

    const keys = ["standName"];
    const allUsers = useCallback(async (int) => {
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_API_URL}/api/stands/users_all_stands/${auth.userId}`,
                "GET",
                undefined,
                { Authorization: "Bearer " + auth.token }
            );
            if (responseData.download_links){
                let link ="";
                link = document.createElement("a");
                link.href = responseData.download_links[int];
                link.download = responseData.download_links[int];
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
 
            }
            
        } catch (err) {}
    }, [sendRequest,auth.token,auth.userId]);
    const handleUsers = () => {
        allUsers(0);
    };
    const handleInterested = () => {
        allUsers(1);
    };

    const search = (data)=>{
    return data.filter(
        (item)=> 
        keys.some(key => item[key].toLowerCase().includes(query))
        );
    }

    useEffect(() => {
        const fetchStands = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_API_URL}/api/stands/`,
                    "GET",
                    null,
                    { Authorization: "Bearer " + auth.token }
                );
                setLoadedStands(responseData.stands);
            } catch (err) {}
        };
        fetchStands();
    }, [sendRequest, auth.token]);

    return (
        <React.Fragment>
            <img src={BannerStands} width="100%" alt="" />
        
            <BackButton/>
            <div className="stands-list__header">
            <div className="search-bar__container_stands">
                <input className="search-bar_stands" type="text" placeholder="Buscar por nombre de Stand ..." onChange={(e) => setQuery(e.target.value)}/>
            </div>
            {auth.loggedUserData.userType !== "user"  && (<React.Fragment>
                <div className="stands-list__header__button">
                    <Button status="active" onClick={handleUsers}>
                    <BsFileEarmarkPerson className="backend-imageButton__icon" />
                        Descargar Usuarios
                    </Button>
                </div>
                <div className="stands-list__header__button">
                    <Button status="active" onClick={handleInterested}>
                    <BsFillHeartFill className="backend-imageButton__icon" />
                        Descargar Me gusta
                    </Button>
                </div>
                <div className="stands-list__header__button">
                    <Button status="active" href={`../../../backend/lottery`}>
                    <BsFillTagFill className="backend-imageButton__icon" />
                        Generar Sorteo
                    </Button>
                </div>
                </React.Fragment>)}
                
            </div>
       
           
            <div className="stands-list-container">
                {!isLoading &&
                    loadedStands &&
                    search(loadedStands).map((stand) => {
                        if (stand.active !== "Eliminado") {
                            return (
                                <div
                                    className="stands-item-container"
                                    key={stand._id}
                                >
                                    <div className="stand-left-container">
                                    <div className="stands-list-image-container">
                                        <img
                                            className="stands-lists-image"
                                            src={`${process.env.REACT_APP_API_URL}/${stand.navIcon}`}
                                            alt=""
                                        />
                                    </div>
                                    <div>
                                        <Button status="active" className="stands-list__see__button" href={`../../../stands/${stand.standName}`}>
                                        <BsFillEyeFill className="backend-imageButton__icon" />
                                            Ver
                                        </Button>
                                    </div>
                                    </div>
                                    <div className="stands-list-info-container">
                                        <div className="stands-list-info__title">
                                            {stand.standName}
                                        </div>
                                        <div className="stands-list-info__button">
                                           
                                            <Link
                                                to={`../../../backend/stands/${stand.standName}`}
                                            >
                                            <BsPencil className="standlist-imageButton__icon" />
                                                Ir a edición
                                            </Link>
                                            <Link
                                                to={`../../../backend/analytic/${stand.standName}`}
                                            >
                                            <BsFileBarGraphFill className="standlist-imageButton__icon" />
                                                Ver Analíticas
                                            </Link>
                                            <Link
                                                to={`../../../backend/cupons/${stand.standName}`}
                                            >
                                            <BsFillDiamondFill className="standlist-imageButton__icon" />
                                                Cupones
                                            </Link>
                                            <Link
                                                to={`../../../backend/qr/${stand.standName}`}
                                            >
                                            <BsFillFileCodeFill className="standlist-imageButton__icon" />
                                                Generar QR
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            );
                        } else {
                            return null;
                        }
                    })}
                    
            </div>
            
        </React.Fragment>
    );
};

export default StandsList;
