import React, { useContext, useEffect, useState, useRef } from "react";

import CreateModal from "../components/CreateUserModal";
import BannerAdminUsers from '../assets/Banner2.png'; 
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/AuthContext";
import Button from "../../../shared/components/FormElements/Button";
import BackButton from "../../../shared/components/UIElements/BackButton";
import UsersList from "../components/UsersList";
import PaginationNew from "../../../shared/components/UIElements/PaginationNew";

import { BsFillPlusCircleFill } from "react-icons/bs";

import "./Users.css";

const Users = () => {
    const inputSearchRef = useRef(null);
    const auth = useContext(AuthContext);
    const { isLoading, sendRequest } = useHttpClient();
    const [loadedUsers, setLoadedUsers] = useState();
    const [showAuth, setShowAuth] = useState(false);
    const [loggedUser, setLoggedUser] = useState(null);
    const [userrefresh, setUsersRefresh] = useState(false);
    const [userSearch, setUserSearch] = useState("");

    let PageSize = 20;

    const handleCloseAuth = () => setShowAuth(false);

    const handleShowSignup = () => {
        setShowAuth(true);
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [totalElements, setTotalElements] = useState(0);
  
    const handleSearch = () => {
        setUserSearch(inputSearchRef.current.value);
    };

    const handleOnChange = (event) => {

        if(event.target.value === ''){
            setUserSearch('');
        }

    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          // 👇 Get input value
          setUserSearch(event.target.value);
        }
      };

        const fetchUsers = async (page) => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_API_URL}/api/users?page=${page}&limit=${PageSize}&filter=${userSearch}`,
                    "GET",
                    null,
                    { Authorization: "Bearer " + auth.token }
                );
                setLoadedUsers(responseData.users);
                setTotalElements(responseData.paginate.totalElements);
                setUsersRefresh(false);
            } catch (err) {}
        };
       
  
    useEffect(() => {
        fetchUsers(currentPage);
    }, [currentPage,sendRequest, userrefresh, auth.token,userSearch]);
  
 
    useEffect(() => {
        const fetchUser = async () => {
            if (auth.userId) {
                try {
                    const responseData = await sendRequest(
                        `${process.env.REACT_APP_API_URL}/api/users/${auth.userId}`,
                        "GET",
                        null,
                        { Authorization: "Bearer " + auth.token }
                    );
                    setLoggedUser(responseData.user);
                } catch (err) {}
            } else {
                setLoggedUser(null);
            }
        };
        fetchUser();
    }, [auth.login, auth.logout, auth.userId, auth.token, sendRequest]);

    return (
        <React.Fragment>
             <img src={BannerAdminUsers} width="100%" alt="" />
             <BackButton/>
            <CreateModal
                show={showAuth}
                onHide={handleCloseAuth}
                refresh={setUsersRefresh}
            />
          <div className="admin-users-list__header">
          <div className="search-bar__container_admin-users">
                        <input
                            id="Search"
                            ref={inputSearchRef}
                            className="search-bar_admin-users"
                            type="search"
                            placeholder="Buscar por nombre, apellido o mail ..."
                            onChange={handleOnChange}
                            onKeyDown={handleKeyDown}
                        />
                        <Button
                         onClick={handleSearch}
                        className="search-bar-button"
                        >
                        Buscar
                    </Button>
            </div>
            <div className="users__container">
                <Button status="active" onClick={handleShowSignup}>
                <BsFillPlusCircleFill className="backend-imageButton__icon" />
                    Crear Expositor
                </Button>
            </div>
             </div>
           
            
            {!isLoading && loggedUser && loadedUsers && (
                <React.Fragment>
    
                    {!isLoading && loggedUser && loadedUsers && (
                        <div>
                        <PaginationNew
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={totalElements}
                            pageSize={PageSize}
                            onPageChange={page => setCurrentPage(page)}
                            />
                             <UsersList
                                userslist={loadedUsers}
                                user={loggedUser}
                                refresh={setUsersRefresh}
                                // query={userSearch}
                            /> 
                        </div>
                    )}
                </React.Fragment>
            )}
           
        </React.Fragment>
    );
};

export default Users;
