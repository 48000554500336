import React from "react";

import "./TitleStandCard.css";

const TitleStandCard = (props) => {
    

    return (
        <div
            className={`textsolo-stand-card ${
                props.index === 0 ? "textsolo-stand-card--firstchild" : ""
            } ${
                props.preview ? "textsolo-stand-card--preview" : ""
            }`}
        >
        <div className="textsolo-stand-card__title">
                <span
                    className="textsolo-stand-card__title"
                    style={{ color: props.cardTextColor }}
                >
                    {props.firstTitle}{" "}
                </span>
                
            </div>

        </div>
    );
};

export default TitleStandCard;
