import React, { useState } from "react";

import Button from "../../shared/components/FormElements/Button";
import GenericModal from "../../shared/components/UIElements/GenericModal";
import CustomQuestionsForm from "../../shared/components/FormElements/CustomQuestionsForm";
import { BsFillArrowRightCircleFill } from "react-icons/bs";


import "./CustomQuestionsCard.css";

const CustomQuestionsCard = (props) => {
    const [modalShow, setModalShow] = useState(false);
    console.log(props);
    return (
        <React.Fragment>
            <GenericModal
                title={"Responder Pregunta(s)"}
                nonError={true}
                buttonless={true}
                show={modalShow}
                setModalShow={setModalShow}
            >
                <CustomQuestionsForm
                        initialValues={props.initialValues}
                        helpSchema={props.helpSchema}
                        clearError={props.clearError}
                        sendRequest={props.sendRequest}
                        auth={props.auth}
                        standFetch={props.standFetch}
                        standName={props.standName}
                        error={props.error}
                        formFields={props.formFields}
                        customFormFields={props.customFormFields}
                        history={props.history}
                        setModalShow={setModalShow}
                        cardForm={true}
                    />
            </GenericModal>

            <div
                className={`default-stand-card ${
                    props.index === 0 ? "default-stand-card--firstchild" : ""
                } ${props.preview ? "default-stand-card--preview" : ""}`}
            >
                <div className="question-stand-card__title">
                    <span
                        className="default-stand-card__title"
                        style={{ color: props.cardTextColor }}
                    >
                        {props.firstTitle}
                    </span>
                </div>
                <div className="d-flex justify-content-center">
                    <Button
                        className=""
                        onClick={() => setModalShow(true)}
                        status="active"
                    >
                    <BsFillArrowRightCircleFill className="backend-imageButton__icon" />
                        {props.buttonText}
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default CustomQuestionsCard;
