import React from "react";

import './HomePageFormText.css'

const HomePageFormText = () =>{

    return(
    <div className="homepage__content">
        <div className="homepage__content-center">
            <h2 className="homepage__title--big">SIMPLE<span className="homepage__title--bigcolor">STAND</span></h2>
            <h3 className="homepage__title"></h3>
            <p className="homepage__subcontent--bullet" ></p>
            <p className="homepage__subcontent" >Te responderemos a la mayor brevedad posible, pero recuerda que nos puedes escribir a nuestro <span>
            <a className="homepage__sub-title" href={`https://wa.me/5491123932179`}>
            Whatsapp
            </a>
            </span></p>
            <p className="homepage__sub-title" >Email</p>
            <p className="homepage__subcontent" >simplestand@simplestand.co</p>
        </div>
    </div>



    );

};

export default HomePageFormText;