import React from "react";

import { Formik } from "formik";
import { GenericForm, FormInputComponent } from "../FormElements/GenericForm";
import { BsFillArrowRightCircleFill , BsFillArrowLeftCircleFill } from "react-icons/bs";

import Button from "../FormElements/Button";

export const CustomQuestionsForm = (props) => {
    return (
        <Formik
            initialValues={props.initialValues}
            validateOnMount
            validationSchema={props.helpSchema}
            onSubmit={async (values) => {
                props.clearError();
                try {
                    await props.sendRequest(
                        `${process.env.REACT_APP_API_URL}/api/users/${props.auth.userId}/formfields`,
                        "POST",
                        JSON.stringify(values),
                        {
                            Authorization: "Bearer " + props.auth.token,
                            "Content-type": "application/json",
                        }
                    );
                    props.standFetch();
                    props.setModalShow(false);
                    props.history.push(`/stands/${props.standName}`);
                } catch (err) {}
            }}
        >
            {() => {
                return (
                    <GenericForm
                        title={null}
                        cardStyle={{ margin: "0px" }}
                        error={props.error}
                    >
                        {props.formFields &&
                            props.formFields.map(
                                (formField, index) => {
                                    return (
                                        <FormInputComponent
                                            key={index}
                                            formInputLayout="column"
                                            fieldAs={formField.fieldAs}
                                            fieldType={formField.fieldType}
                                            fieldName={`formFields.${formField._id}`}
                                            fieldDescription={
                                                formField.fieldDescription
                                            }
                                            fieldPlaceholder={
                                                formField.fieldPlaceholder
                                            }
                                            fieldSelect={formField.fieldSelect}
                                        />
                                    );
                                }
                            )}
                        {props.customFormFields &&
                            props.customFormFields.map(
                                (customFormField, index) => {
                                    return (
                                        <FormInputComponent
                                            key={index}
                                            formInputLayout="column"
                                            fieldAs={customFormField.fieldAs}
                                            fieldType={
                                                customFormField.fieldType
                                            }
                                            fieldName={`customFormFields.${customFormField._id}`}
                                            fieldDescription={
                                                customFormField.fieldDescription
                                            }
                                            fieldPlaceholder={
                                                customFormField.fieldPlaceholder
                                            }
                                            fieldSelect={
                                                customFormField.fieldOptions
                                            }
                                        />
                                    );
                                }
                            )}
                        <div className="generic-form__footer">
                            <div
                                className={`generic-form__button-block${
                                    props.cardForm ? "" : "--grid"
                                }`}
                            >
                                <Button
                                    className=""
                                    type="submit"
                                    status="active"
                                >
                                 <BsFillArrowRightCircleFill className="standlist-imageButton__iconblack" />
                                    Enviar
                                </Button>
                                {!props.cardForm && (
                                    <Button
                                        to="/"
                                        className="generic-form__back-button"
                                    >
                                     <BsFillArrowLeftCircleFill className="standlist-imageButton__iconblack" />
                                        Atrás
                                    </Button>
                                )}
                            </div>
                        </div>
                    </GenericForm>
                );
            }}
        </Formik>
    );
};

export default CustomQuestionsForm;
