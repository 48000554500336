import React, { useEffect } from "react";

import GenericModal from "../../../shared/components/UIElements/GenericModal";

import "./TemplatesModal.css";
import iconoimagen from "../assets/imagen.png";
import iconoimagentitulotexto from "../assets/imagentitulotexto.png";
import iconomapa from "../assets/mapa.png";
import icononumeros from "../assets/numeros.png";
import iconotitulo from "../assets/titulo.png";
import iconotitulotexto from "../assets/titulotexto.png";
import iconotitulotextoimagen from "../assets/titulotextoimagen.png";
import iconovideo from "../assets/video.png";
//import iconocarrusel from "../assets/carrusel.png";
import iconoacordeon from "../assets/acordeon.png";
import iconoecommerce from "../assets/ecommerce.png";


const TemplatesModal = (props) => {
    useEffect(() => {
        
    }, [props]);

    return (
        <GenericModal
            show={props.show}
            error={false}
            title={"Ejemplo de Módulos"}
            buttonless={true}
            closeFunction={props.onClose}
            className={"templates-preview__modal"}
        >
            <div className="templates-preview__container">
                <div className="templates-banner__step">
                    <div className="templates-banner__square">
                        <img
                            className="templates-banner__square-icon"
                            src={iconotitulo}
                            alt=""
                        />
                    </div>
                    <div className="templates-banner__step-texts">
                        <h4 className="templates-banner__step-title">
                            Título Solo
                        </h4>
                    </div>
                </div>
                <div className="templates-banner__step">
                    <div className="templates-banner__square">
                        <img
                            className="templates-banner__square-icon"
                            src={iconotitulotexto}
                            alt=""
                        />
                    </div>
                    <div className="templates-banner__step-texts">
                        <h4 className="templates-banner__step-title">
                            Título y Texto
                        </h4>
                    </div>
                </div>
                <div className="templates-banner__step">
                    <div className="templates-banner__square">
                        <img
                            className="templates-banner__square-icon"
                            src={iconotitulotextoimagen}
                            alt=""
                        />
                    </div>
                    <div className="templates-banner__step-texts">
                        <h4 className="templates-banner__step-title">
                            Título, Texto e Imagen
                        </h4>
                    </div>
                </div>
                <div className="templates-banner__step">
                    <div className="templates-banner__square">
                        <img
                            className="templates-banner__square-icon"
                            src={iconoimagen}
                            alt=""
                        />
                    </div>
                    <div className="templates-banner__step-texts">
                        <h4 className="templates-banner__step-title">
                            Imagen Sola 
                        </h4>
                    </div>
                </div>
                <div className="templates-banner__step">
                    <div className="templates-banner__square">
                        <img
                            className="templates-banner__square-icon"
                            src={iconoimagentitulotexto}
                            alt=""
                        />
                    </div>
                    <div className="templates-banner__step-texts">
                        <h4 className="templates-banner__step-title">
                            Imagen, Título y Descripción
                        </h4>
                    </div>
                </div>
                <div className="templates-banner__step">
                    <div className="templates-banner__square">
                        <img
                            className="templates-banner__square-icon"
                            src={icononumeros}
                            alt=""
                        />
                    </div>
                    <div className="templates-banner__step-texts">
                        <h4 className="templates-banner__step-title">
                            Número, título y descripción
                        </h4>
                    </div>
                </div>
                <div className="templates-banner__step">
                    <div className="templates-banner__square">
                        <img
                            className="templates-banner__square-icon"
                            src={iconovideo}
                            alt=""
                        />
                    </div>
                    <div className="templates-banner__step-texts">
                        <h4 className="templates-banner__step-title">
                            Video
                        </h4>
                    </div>
                </div>
                <div className="templates-banner__step">
                    <div className="templates-banner__square">
                        <img
                            className="templates-banner__square-icon"
                            src={iconomapa}
                            alt=""
                        />
                    </div>
                    <div className="templates-banner__step-texts">
                        <h4 className="templates-banner__step-title">
                            Mapa
                        </h4>
                    </div>
                </div>
                {/*<div className="templates-banner__step">
                    <div className="templates-banner__square">
                        <img
                            className="templates-banner__square-icon"
                            src={iconocarrusel}
                            alt=""
                        />
                    </div>
                    <div className="templates-banner__step-texts">
                        <h4 className="templates-banner__step-title">
                            Carrusel de imágenes
                        </h4>
                    </div>
                </div>*/}
                <div className="templates-banner__step">
                    <div className="templates-banner__square">
                        <img
                            className="templates-banner__square-icon"
                            src={iconoacordeon}
                            alt=""
                        />
                    </div>
                    <div className="templates-banner__step-texts">
                        <h4 className="templates-banner__step-title">
                            Acordeon
                        </h4>
                    </div>
                </div>
                <div className="templates-banner__step">
                    <div className="templates-banner__square">
                        <img
                            className="templates-banner__square-icon"
                            src={iconoecommerce}
                            alt=""
                        />
                    </div>
                    <div className="templates-banner__step-texts">
                        <h4 className="templates-banner__step-title">
                            Ecommerce
                        </h4>
                    </div>
                </div>
               
            </div>
        </GenericModal>
    );
};

export default TemplatesModal;
