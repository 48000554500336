import React from "react";

import "./UsersList.css";



const UsersList = (props) => {



  if (props.user) {
  return (
    <div className="analytic-list__container">
      <table className="analytic-list__container-table">
        <thead>
          <tr>
            <th className="analytic-list__firstchild">Fecha</th>
            <th>Nombre</th>
            <th>Apellido</th>
            <th className="analytic-list__lastchild">Email</th>
          </tr>
        </thead>
        <tbody>
          {props.userslist.map((item,index) => (
            
            <tr key={index}>
            
              <td>{item.fecha}</td>
              <td>{item.nombre}</td>
              <td>{item.apellido}</td>
              <td>{item.mail}</td>
            </tr>
          ))}
          
        </tbody>
      </table>
    </div>
  );}else {
    return null;
}
};

export default UsersList;
