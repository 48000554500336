import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import "./MapStandCard.css";

mapboxgl.accessToken =
    "pk.eyJ1IjoiYW5ndWlsYTU1IiwiYSI6ImNsZDBmNnhoNjBmMDgzcXFqNjB1aGhpcTQifQ.A3RmTWqkG_LOlRs2AIypkQ";
// in render()

const MapStandCard = (props) => {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(props.longitude);
    const [lat, setLat] = useState(props.latitude);
    const [zoom, setZoom] = useState(14);

    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: "mapbox://styles/mapbox/streets-v12",
            center: [lng, lat],
            zoom: zoom,
        });

        // var marker = new mapboxgl.Marker({
        //     color: props.hexColor,
        // })
        //     .setLngLat([props.longitude, props.latitude])
        //     .addTo(map.current);
    });

    useEffect(() => {
        if (!map.current) return; // wait for map to initialize
        map.current.on("move", () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        });
    });

    return (
        <div
            ref={mapContainer}
            className={`${
                props.index === 0 ? "map-container--firstchild" : ""
            } ${props.preview ? "map-container--preview" : "map-container"}`}
        />
    );
};

export default MapStandCard;
