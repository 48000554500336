import React from 'react';

import './Badge.css';

const Badge = props => {
  return (
    <div
      style={props.style}
      className={`status-badge status-badge--${props.status} ${props.className || ''}`}
    >
      {props.children}
    </div>
  );
};

export default Badge;
