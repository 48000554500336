import { React, useState, useEffect } from "react";

import { Modal } from "react-bootstrap";
import Button from "../FormElements/Button";
import { IoCloseSharp } from "react-icons/io5";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import "./GenericModal.css";

const GenericModal = (props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        !!props.onClear && props.onClear();
        !!props.closeFunction && props.closeFunction();
        setShow(false);
        props.setModalShow && props.setModalShow(false);
    };

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    return (
        <Modal
            className={`generic-modal ${props.className}`}
            onCancel={handleClose}
            show={show}
        >
            <div className="generic-modal-content">
                <div className="generic-modal__header">
                    <button
                        onClick={handleClose}
                        className="generic-modal__close"
                    >
                        <IoCloseSharp />
                    </button>
                    <div className="generic-modal__title">{props.title}</div>
                </div>

                {props.error && props.nonError && (
                    <div className={`generic-modal__regular-outline`}>
                        <div className={`generic-modal__regular-message`}>
                            {props.message}
                        </div>
                    </div>
                )}
                {props.error && !props.nonError && (
                    <div
                        className={`generic-modal__${
                            props.error ? "error" : "regular"
                        }-outline`}
                    >
                        <div
                            className={`generic-modal__${
                                props.error ? "error" : "regular"
                            }-message`}
                        >
                            {props.message}
                        </div>
                    </div>
                )}
                {props.children}
                {!props.buttonless && (
                    <div className="generic-modal__footer">
                        <Button
                            onClick={handleClose}
                            className=""
                            status="active"
                        >
                         <BsFillArrowRightCircleFill className="backend-imageButton__icon" />
                            Aceptar
                        </Button>
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default GenericModal;
