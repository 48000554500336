import React from "react";
import { Field, Form, ErrorMessage } from "formik";

import "./GenericForm.css";

const ColorPicker = (props) => {
    return (
        <div className="color-picker__holder">
            <div
                className="color-picker__button"
                onClick={() => props.showHexPickerHandler(props.fieldName)}
            ></div>
        </div>
    );
};

const FileUpload = (props) => {
    return (
        <input
            multiple={props.multiple}
            type="file"
            name={props.fieldName}
            className="generic-form__form-input generic-form__form-input--file-upload generic-form__form-input--inline"
            onChange={(event) => {
                props.setFieldValue(
                    props.fieldName,
                    event.currentTarget.files[0]
                );
            }}
        />
    );
};

export const GenericForm = (props) => {
    return (
        <Form className="generic-form" encType="multipart/form-data">
            {props.error && (
                <div className="generic-form__api-error-placeholder">
                    <div className="generic-form__api-error">{props.error}</div>
                </div>
            )}
            <div className="generic-form__content">
                <div className="generic-form__card" style={props.cardStyle}>
                    {props.title && (
                        <h2 className="stand-form__title--big">
                            {props.title}
                        </h2>
                    )}
                    {props.children}
                </div>
            </div>
        </Form>
    );
};

export const FormInputComponent = (props) => {
    return (
        <div
            className={`generic-form__input-block generic-form__input-block--${props.formInputLayout} ${props.className}`}
        >
            <div className="generic-form__error-placeholder generic-form__error-placeholder--column">
                <ErrorMessage
                    name={props.fieldName}
                    component="div"
                    className="generic-form__validation-error"
                />
            </div>
            <label>{props.fieldDescription}</label>
            {!props.fileUpload &&
                props.onChange && (
                    <Field
                        type={props.fieldType}
                        as={props.fieldAs}
                        multiple={props.multiple}
                        name={props.fieldName}
                        disabled={props.disabled}
                        placeholder={props.fieldPlaceholder}
                        onChange={props.onChange}
                        className="generic-form__form-input generic-form__form-input--inline"
                    >
                        {props.fieldSelect &&
                            props.fieldSelect.map((field, index) => {
                                return (
                                    <option
                                        key={index}
                                        value={field.value}
                                        className={field.font}
                                        selected={field.selected}
                                    >
                                        {field.text}
                                    </option>
                                );
                            })}
                    </Field>
                )}

            {!props.fileUpload &&
                !props.onChange && (
                    <Field
                        type={props.fieldType}
                        as={props.fieldAs}
                        multiple={props.multiple}
                        name={props.fieldName}
                        disabled={props.disabled}
                        placeholder={props.fieldPlaceholder}
                        className="generic-form__form-input generic-form__form-input--inline"
                    >
                        {props.fieldSelect &&
                            props.fieldSelect.map((field, index) => {
                                return (
                                    <option
                                        key={index}
                                        value={field.value}
                                        className={field.font}
                                        selected={field.selected}
                                    >
                                        {field.text}
                                    </option>
                                );
                            })}
                    </Field>
                )}

            {props.fileUpload && (
                <FileUpload
                    setFieldValue={props.setFieldValue}
                    fieldName={props.fieldName}
                    multiple={props.multiFile}
                />
            )}
            {props.colorPickerHandler && (
                <ColorPicker
                    showHexPickerHandler={props.colorPickerHandler}
                    fieldName={props.fieldName}
                />
            )}
        </div>
    );
};

export const FieldArrayComponent = (props) => {
    return (
        <React.Fragment>
            <hr />
            <h3>{props.title}</h3>
            {props.children}
        </React.Fragment>
    );
};
