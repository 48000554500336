import React from "react";

import { FormInputComponent } from "../../../../shared/components/FormElements/GenericForm";

const AccordionStandCardEdition = (props) => {
    return (
        <React.Fragment>
            <FormInputComponent
                fieldType="text"
                fieldName={`cards.${props.index}.firstTitle`}
                fieldDescription={"Primer Título"}
                fieldPlaceholder=""
            />
            <FormInputComponent
                fieldType="text"
                fieldName={`cards.${props.index}.description`}
                fieldDescription="Descripción"
                fieldPlaceholder=""
            />
        </React.Fragment>
    );
};

export default AccordionStandCardEdition;
