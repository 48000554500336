import React from "react";

import { FormInputComponent } from "../../../../shared/components/FormElements/GenericForm";

const ImageStandCardEdition = (props) => {
    return (
        <React.Fragment>
            <FormInputComponent
                fieldType="file"
                fieldName={`cards.${props.index}.image`}
                fieldDescription="Imagen"
                fieldPlaceholder=""
                fileUpload={true}
                setFieldValue={props.setFieldValue}
            />
            <FormInputComponent
                fieldType="select"
                fieldAs="select"
                fieldSelect={[
                    {
                        value: "",
                        text: "Seleccione Opción",
                    },
                    {
                        value: "None",
                        text: "Sin Botón",
                    },
                    {
                        value: "Action",
                        text: "Botón con call to action",
                    },
                    {
                        value: "Url",
                        text: "Botón a url",
                    },
                ]}
                fieldName={`cards.${props.index}.buttonType`}
                fieldDescription={`Tipo de Botón`}
                fieldPlaceholder=""
                onChange={(e) => {
                    props.setFieldValue(
                        `cards.${props.index}.buttonType`,
                        e.target.value
                    );
                }}
            />
            {props.card.buttonType === "Action" && (
                <div className="card-edition-form__input-block--grid">
                    <div className="card-edition-form__input-block--column">
                        {" "}
                        <FormInputComponent
                            fieldType="select"
                            fieldAs="select"
                            fieldSelect={[
                                {
                                    value: 0,
                                    text: "Seleccione",
                                },
                                {
                                    value: "text",
                                    text: "Texto",
                                },
                                {
                                    value: "select",
                                    text: "Desplegable con opciones",
                                },
                            ]}
                            fieldName={`cards.${props.index}.formInformation.fieldAs`}
                            fieldDescription={`Tipo de Campo`}
                            fieldPlaceholder=""
                            className={"card-edition-form__input-block--taller"}
                        />
                        <FormInputComponent
                            fieldType="text"
                            fieldName={`cards.${props.index}.formInformation.fieldDescription`}
                            fieldDescription="Descripción del campo de formulario"
                            fieldPlaceholder=""
                        />
                    </div>

                    <div className="card-edition-form__input-block--column">
                        <FormInputComponent
                            fieldType="text"
                            fieldName={`cards.${props.index}.formInformation.fieldSelect`}
                            fieldDescription="Insertar valores separados por punto y coma, empezando con el título. (Solo en caso de utilizar desplegable con opciones)"
                            fieldPlaceholder="Ejemplo: Textil;Comercial;Ganadero"
                            className={"card-edition-form__input-block--taller"}
                        />
                    </div>
                </div>
            )}
            {props.card.buttonType === "Url" && (
                <FormInputComponent
                    fieldType="text"
                    fieldName={`cards.${props.index}.buttonLink`}
                    fieldDescription="Link de botón (Sin incluir https://)"
                    fieldPlaceholder=""
                />
            )}
        </React.Fragment>
    );
};

export default ImageStandCardEdition;
