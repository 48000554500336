import React from "react";

import "./NumberTitleTextStandCard.css";
import Button from "../../shared/components/FormElements/Button";
import { BsFillArrowRightCircleFill } from "react-icons/bs";

const NumberTitleTextStandCard = (props) => {
    

    return (
        <React.Fragment>
        <div
            className={`numbertitletext-stand-card ${
                props.index === 0 ? "numbertitletext-stand-card--firstchild" : ""
            } ${
                props.preview ? "numbertitletext-stand-card--preview" : ""
            }`}
        >
        <div className="numbertitletext-stand-card__title">
                <span
                    className="numbertitletext-stand-card__title"
                    style={{ color: props.hexColor }}
                >
                    {props.firstTitle}{" "}
                </span>
                {props.secondTitle && (
                    <div className="numbertitletext-stand-card__subtitle">
                        <span
                                className="numbertitletext-stand-card__subtitle"
                                // style={{ color: props.cardTextColor }}
                            >
                                {props.secondTitle}
                            </span>
                    </div>
                )}
            </div>
            {props.description && (
                        <div
                            className="numbertitletext-stand-card__description"
                            style={{ color: props.cardTextColor }}
                        >
                            {props.description}
                        </div>
                    )}
            {props.buttonLink && (
                <div className="mt-4 text-center">
                    <Button
                        className=""
                        type="submit"
                        style={{
                            backgroundColor: props.hexColor,
                            color: props.buttonTextColor,
                            textAlign:"center",
                        }}
                        href={`//${props.buttonLink}`}
                        target="_blank"
                    > <BsFillArrowRightCircleFill className="backend-imageButton__icon" />
                        {props.buttonText}
                    </Button>
                </div>
        )}
       
        </div>
        <hr className="numbertitletext__line"/>
        </React.Fragment>
    );
};

export default NumberTitleTextStandCard;
