import React, {useState,useContext, useEffect, useCallback} from "react";
import { Formik, Form } from "formik";

import "./UsersList.css";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import Button from "../../../shared/components/FormElements/Button";
import { AuthContext } from "../../../shared/context/AuthContext";
import {
  FormInputComponent,
} from "../../../shared/components/FormElements/GenericForm";


const UsersList = (props) => {
  const auth = useContext(AuthContext);
  const { sendRequest, error, clearError } = useHttpClient();
  const [adminUsers, setAdminUsers] = useState([]);

  const getAdminUsers = useCallback(async () => {
    try {
        const responseData = await sendRequest(
            `${process.env.REACT_APP_API_URL}/api/users/admin`
        );
        setAdminUsers(responseData.adminusers);
        
    } catch (err) {}
}, [sendRequest]);

useEffect(() => {
  getAdminUsers();
}, [getAdminUsers]);

  if (props.user) {
  return (
    <div className="users-list__container">
      <table className="admin-users-list__container-table">
        <thead>
          <tr>
            <th className="admin-users-list__firstchild">Nombre</th>
            <th>Apellido</th>
            <th>Email</th>
            {props.user && props.user.userType !== "superadmin" && <th className="admin-users-list__lastchild">Tipo de Perfil</th>}
            {props.user && props.user.userType === "superadmin" && <th>Tipo de Perfil</th>}
            {props.user && props.user.userType === "superadmin" && <th className="admin-users-list__lastchild">Visibilidad Perfil</th>}
          </tr>
        </thead>
        <tbody>
          {props.userslist.map((item) => (
            <tr key={item.id}>
              <td className="admin-users-list__name">{item.firstName}</td>
              <td className="admin-users-list__name">{item.lastName}</td>
              <td>{item.email}</td>
              {props.user.id && props.user.userType === "admin" && (
                <td>{item.userType}</td>
              )}
              {props.user.id && props.user.userType === "superadmin" && (
                <React.Fragment>
                <td>
                  <Formik
                    initialValues={{
                      firstName: item.firstName,
                      lastName: item.lastName,
                      image: null,
                      password: "",
                      changePassword: "",
                      creator: item.creator,
                      type: item.userType,
                    }}
                    onSubmit={async (values) => {
                    clearError();
                    const formData = new FormData();
                    formData.append("firstName", values.firstName);
                    formData.append("lastName", values.lastName);
                    formData.append("password", values.password);
                    formData.append("image", values.image);
                    formData.append("creator", values.creator);
                    formData.append("type", values.type);
                    
                    try {
                        await sendRequest(
                            `${process.env.REACT_APP_API_URL}/api/users/role/${item.id}`,
                                    "PATCH",
                                    formData,
                            { Authorization: "Bearer " + auth.token }
                        );
                        props.refresh(true);
                    } catch (err) {}
                }}
                  >
                    <Form>
                      <div className="user-list__select-container">
                        <div>
                            <FormInputComponent
                                  formInputLayout="column"
                                  fieldAs="select"
                                  fieldName="type"
                                  fieldPlaceholder="Seleccione"
                                  fieldSelect={[
                                      {
                                          value: "",
                                          text: "Seleccione",
                                      },
                                      {
                                          value: "admin",
                                          text: "Administrador",
                                      },
                                      {
                                          value: "expositor",
                                          text: "Expositor",
                                      },
                                      {
                                          value: "user",
                                          text: "Usuario",
                                      },
                                  ]} //cambiar
                              />
                            
                          </div>
                          <div>
                            <Button status="active" type="submit" className="user-list__save-button">
                              Guardar
                            </Button>
                          </div>
                      </div>
                    </Form>
                  </Formik>
                </td>
                <td>
                  <Formik
                    initialValues={{
                      firstName: item.firstName,
                      lastName: item.lastName,
                      image: null,
                      password: "",
                      changePassword: "",
                      creator: item.creator,
                      type: item.userType,
                    }}
                    onSubmit={async (values) => {
                    clearError();
                    const formData = new FormData();
                    formData.append("firstName", values.firstName);
                    formData.append("lastName", values.lastName);
                    formData.append("password", values.password);
                    formData.append("image", values.image);
                    formData.append("creator", values.creator);
                    formData.append("type", values.type);
                    
                    try {
                        await sendRequest(
                            `${process.env.REACT_APP_API_URL}/api/users/role/${item.id}`,
                                    "PATCH",
                                    formData,
                            { Authorization: "Bearer " + auth.token }
                        );
                        props.refresh(true);
                    } catch (err) {}
                }}
                  >
                    <Form>
                      <div className="user-list__select-container">
                        <div>
                          
                          <FormInputComponent
                                        formInputLayout="column"
                                        fieldAs="select"
                                        fieldSelect={adminUsers}
                                        fieldName="creator"
                                        fieldPlaceholder="Visible por"
                                    />

                          </div>
                          <div>
                            <Button status="active" type="submit" className="mt-2 user-list__save-button">
                              Guardar
                            </Button>
                          </div>
                      </div>
                    </Form>
                  </Formik>
                </td>
                </React.Fragment>
              )}
            </tr>
          ))}
          
        </tbody>
      </table>
    </div>
  );}else {
    return null;
}
};

export default UsersList;
