import { React, useContext } from "react";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { IoCloseSharp } from "react-icons/io5";
import { BsArrowLeftCircleFill , BsFillTagFill } from "react-icons/bs";
import { Modal } from "react-bootstrap";
import GenericModal from "../../../shared/components/UIElements/GenericModal";
import {
    FormInputComponent,
} from "../../../shared/components/FormElements/GenericForm";
import { AuthContext } from "../../../shared/context/AuthContext";
import * as Yup from "yup";
import "./LotteryModal.css";

import { useHttpClient } from "../../../shared/hooks/http-hook";
import Button from "../../../shared/components/FormElements/Button";

const LotterySchema = Yup.object().shape({
    titleName: Yup.string().max(24, "Demasiado largo").required("Requerido"),
    winners: Yup.number().max(24, "Demasiado grande").required("Requerido"),
    backups: Yup.number().max(24, "Demasiado grande").required("Requerido"),
    stands: Yup.mixed().required("Requerido"),
    initialDate: Yup.string().max(24, "Demasiado largo"),
    endDate: Yup.string().max(24, "Demasiado largo"),
});

const LotteryModal = (props) => {
    const { sendRequest, error, clearError } = useHttpClient();

    const auth = useContext(AuthContext);

    const standName = props.stand;


    const hideHandler = () => {
        return props.onHide();
    };
    let standsArray=[];
    if (props.stands){
        props.stands.map((stand) => {
            const newStand = {
                value: stand.id,
                text: stand.standName,
            };

            standsArray.push(newStand);
        });
    }

    return (
        <Modal className="lottery-update-modal" show={props.show}>
            <div className="lottery-update-modal__header">
                <button
                    className="lottery-update-modal__close"
                    onClick={props.onHide}
                >
                    <IoCloseSharp />
                </button>
                <div className="lottery-update-modal__title">Sorteo</div>
            </div>
            <Formik
                initialValues={{
                    titleName: "",
                    stands: "",
                    winners: "",
                    filter: 0,
                    quantity: 0,
                    backups: "",
                    initialDate: "",
                    endDate: "",
                }}
                validationSchema={LotterySchema}
                onSubmit={async (values) => {
                    clearError();
                    const formData = new FormData();
                    formData.append("titleName", values.titleName);
                    formData.append("winners", values.winners);
                    formData.append("backups", values.backups);
                    formData.append("filter", values.filter);
                    formData.append("quantity", values.quantity);
                    formData.append("initialDate", values.initialDate);
                    formData.append("endDate", values.endDate);
                    formData.append("stands", values.stands);
                    try {
                        await sendRequest(
                            `${process.env.REACT_APP_API_URL}/api/lottery/`,
                                    "POST",
                                    JSON.stringify({
                                    titleName: values.titleName,
                                    winners: values.winners,
                                    backups: values.backups,
                                    filter: values.filter,
                                    quantity: values.quantity,
                                    initialDate: values.initialDate,
                                    endDate: values.endDate,
                                    stands: values.stands,
                                    processDate : values.processDate
                                }),
                                {
                                    "Content-Type": "application/json",
                                    Authorization: "Bearer " + auth.token,
                                }
                        );
                        props.refresh(true);
                        hideHandler();
                    } catch (err) {}
                }}
            >
                {(formProps) => (
                    <Form className="lottery-update-form">
                        <GenericModal
                            message={error}
                            show={!!error}
                            error={true}
                            title={"Ocurrió un error"}
                            onClear={clearError}
                        />
                        <div className="lottery-update-form__input-block">
                        <h4 className="lottery-update-form__input-label">
                                    Titulo
                        </h4>
                        <div className="lottery-update-form__error-placeholder">
                               
                               <ErrorMessage
                                   name="titleName"
                                   component="div"
                                   className="lottery-update-form__validation-error"
                               />
                           </div>
                           
                            <Field
                                type="text"
                                name="titleName"
                                placeholder="Nombre del sorteo"
                                className="lottery-update-form__form-input"
                            />
                        
                        </div>
                        <div className="lottery-update-form__input-block">
                        <h4 className="lottery-update-form__input-label">
                                    Elegir stands
                        </h4>
                        <div className="lottery-update-form__error-placeholder">
                               
                               <ErrorMessage
                                   name="stands"
                                   component="div"
                                   className="lottery-update-form__validation-error"
                               />
                           </div>
                               
                            <FormInputComponent
                                formInputLayout=""
                                fieldAs="select"
                                multiple={true}
                                fieldSelect={
                                    standsArray
                                }
                                fieldName="stands"
                                fieldPlaceholder=""
                                className="generic-form__input-label-lottery"
                            />
                        </div>
                        <div className="lottery-update-form__input-block lottery-update-form__input-block--grid">
                                <div className="lottery-update-form__input-block lottery-update-form__input-block--column">
                                    <h4 className="lottery-update-form__input-label">
                                        Ganadores
                                    </h4>
                                    <div className="lottery-update-form__error-placeholder lottery-update-form__error-placeholder--column">
                                    
                                    <ErrorMessage
                                        name="winners"
                                        component="div"
                                        className="lottery-update-form__validation-error"
                                    />
                                    </div>
                                   
                                    <Field
                                        type="number"
                                        name="winners"
                                        placeholder="Cantidad de ganadores"
                                        className="lottery-update-form__form-input"
                                    />
                                </div>
                                <div className="lottery-update-form__input-block lottery-update-form__input-block--column">
                                    <h4 className="lottery-update-form__input-label">
                                        Suplentes
                                    </h4>
                                    <div className="lottery-update-form__error-placeholder lottery-update-form__error-placeholder--column">
                                   
                                    <ErrorMessage
                                        name="backups"
                                        component="div"
                                        className="lottery-update-form__validation-error"
                                    />
                                    </div>
                                    
                                    <Field
                                        type="number"
                                        name="backups"
                                        placeholder="Cantidad de suplentes"
                                        className="lottery-update-form__form-input"
                                    />
                                </div>
                            </div>
                        <div className="lottery-update-form__input-block lottery-update-form__input-block--grid">
                                <div className="lottery-update-form__input-block lottery-update-form__input-block--column">
                                    <div className="lottery-update-form__error-placeholder lottery-update-form__error-placeholder--column">
                                   
                                        <ErrorMessage
                                            name="quantity"
                                            component="div"
                                            className="lottery-update-form__validation-error"
                                        />
                                    </div>
                                    <h4 className="lottery-update-form__input-label">
                                        Cantidad
                                    </h4>
                                    <Field
                                        type="number"
                                        name="quantity"
                                        placeholder="Cantidad"
                                        className="lottery-update-form__form-input lottery-update-form__form-input--inline mt-4"
                                    />
                                </div>
                                <div className="lottery-update-form__input-block lottery-update-form__input-block--column">
                                    <div className="lottery-update-form__error-placeholder lottery-update-form__error-placeholder--column">
                                   
                                        <ErrorMessage
                                            name="filter"
                                            component="div"
                                            className="lottery-update-form__validation-error"
                                        />
                                    </div>
                                    <h4 className="lottery-update-form__input-label">
                                        Filtro
                                    </h4>
                                    <FormInputComponent
                                                    formInputLayout="column"
                                                    fieldType="select"
                                                    fieldAs="select"
                                                    fieldSelect={[
                                                        {
                                                            value: 0,
                                                            text: "sin filtro",
                                                        },
                                                        {
                                                            value: 1,
                                                            text: "cantidad de visitas a stands",
                                                        },
                                                        {
                                                            value: 2,
                                                            text: "cantidad de me gusta",
                                                        },
                                                        {
                                                            value: 3,
                                                            text: "cantidad de respuestas respondidas",
                                                        },
                                                        {
                                                            value: 4,
                                                            text: "cantidad de cupones generados",
                                                        },
                                                    ]}
                                                    fieldName="filter"
                                                />
                                </div>
                            </div>
                            <div className="lottery-update-form__input-block lottery-update-form__input-block--grid">
                                <div className="lottery-update-form__input-block lottery-update-form__input-block--column">
                                    <div className="lottery-update-form__error-placeholder lottery-update-form__error-placeholder--column">
                                   
                                    <ErrorMessage
                                        name="initialDate"
                                        component="div"
                                        className="lottery-update-form__validation-error"
                                    />
                                    </div>
                                    <h4 className="lottery-update-form__input-label">
                                        Fecha inicial
                                    </h4>
                                    <Field
                                        type="date"
                                        name="initialDate"
                                        placeholder="Fecha incial de sorteo"
                                        className="lottery-update-form__form-input"
                                    />
                                </div>
                                <div className="lottery-update-form__input-block lottery-update-form__input-block--column">
                                    <div className="lottery-update-form__error-placeholder lottery-update-form__error-placeholder--column">
                                    
                                    <ErrorMessage
                                        name="endDate"
                                        component="div"
                                        className="lottery-update-form__validation-error"
                                    />
                                    </div>
                                    <h4 className="lottery-update-form__input-label">
                                        Fecha final
                                    </h4>
                                    <Field
                                        type="date"
                                        name="endDate"
                                        placeholder="Fecha final de sorteo"
                                        className="lottery-update-form__form-input"
                                    />
                                </div>
                            </div>
                            <div className="lottery-update-form__input-block lottery-update-form__input-block--grid">
                                <div className="lottery-update-form__input-block lottery-update-form__input-block--column">
                                    <div className="lottery-update-form__error-placeholder lottery-update-form__error-placeholder--column">
                                    <h4 className="lottery-update-form__input-label">
                                        Fecha Sorteo
                                    </h4>
                                    <ErrorMessage
                                        name="processDate"
                                        component="div"
                                        className="lottery-update-form__validation-error"
                                    />
                                    </div>
                                    <Field
                                        type="datetime-local"
                                        name="processDate"
                                        placeholder="Fecha de sorteo"
                                        className="lottery-update-form__form-input"
                                    />
                                </div>
                            </div>

                        <div className="lottery-update-form__footer">
                            <Button
                                className="main-button--squared__margin"
                                status={
                                    formProps.isValid && formProps.dirty
                                        ? "active"
                                        : "inactive"
                                }
                                type="submit"
                            > 
                            <BsFillTagFill className="backend-imageButton__icon" />
                                Crear Sorteo
                            </Button>
                            <Button
                                className="user-update__button-cancel"
                                status="inverse"
                                type="button"
                                onClick={hideHandler}
                            ><BsArrowLeftCircleFill className="backend-imageButton__icon" />
                                Cancelar
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Modal>

    );
};

export default LotteryModal;
