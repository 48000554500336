import React, {useState,useContext} from "react";
import { Formik, Form} from "formik";

import { useHttpClient } from "../../../shared/hooks/http-hook";
import Button from "../../../shared/components/FormElements/Button";
import { AuthContext } from "../../../shared/context/AuthContext";

import "./CuponsList.css";



const CuponsList = (props) => {
  const auth = useContext(AuthContext);
  const { sendRequest, error, clearError } = useHttpClient();
  return (
    <React.Fragment>

    <div className="cupon-list__container">
      <table className="cupon-list__container-table">
        <thead>
          <tr>
            <th className="cupon-list__firstchild">Fecha</th>
            <th>Usuario</th>
            <th>Card</th>
            <th>Cupon</th>
            <th>Escaneado</th>
            <th className="cupon-list__lastchild">Acción</th>
          </tr>
        </thead>
        <tbody>
          {props.cuponslist.map((item,index) => (
            
            <tr key={index}>
            
              <td>{item.fecha}</td>
              <td>{item.userName}</td>
              <td>{item.cardId}</td>
              <td>{item.cupon}</td>
              <td>{`${item.scan===false? "No Escaneado":"Escaneado"}`}</td>
              {props.cuponslist && (
                <React.Fragment>
                <td>
                  <Formik
                    initialValues={{
                      userName: item.userName,
                      cardId: item.cardId,
                    }}
                    onSubmit={async (values) => {
                    clearError();
                    const formData = new FormData();
                    formData.append("userName", values.userName);
                    formData.append("cardId", values.cardId);
                    try {
                      const responseData =  await sendRequest(
                            `${process.env.REACT_APP_API_URL}/api/cupons/${props.stand}`,
                                    "PATCH",
                                    JSON.stringify({
                                      userName: values.userName,
                                      cardId: values.cardId,
                                    }),
                            {
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + auth.token,
                            }
                        );
                        if (responseData.status === "OK"){
                          props.refresh(true);
                        }
                        
                    } catch (err) {}
                }}
                  >
                    <Form>
                      <div>
                          <div>
                            <Button status="active" type="submit" className="mt-2">
                              Escanear
                            </Button>
                          </div>
                      </div>
                    </Form>
                  </Formik>
                </td>
                </React.Fragment>
                )}
            </tr>
          ))}
          
        </tbody>
      </table>
    </div>
    </React.Fragment>
  );
};

export default CuponsList;
