import React, { useContext, useState, useEffect } from "react";
import {
    FormInputComponent,
} from "../../shared/components/FormElements/GenericForm";
import { Formik, Form } from "formik";

import "./ImageStandCard.css";

import GenericModal from "../../shared/components/UIElements/GenericModal";
import Button from "../../shared/components/FormElements/Button";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/AuthContext";
import { BsFillArrowRightCircleFill } from "react-icons/bs";

const ImageStandCard = (props) => {
    const auth = useContext(AuthContext);
    const { sendRequest, clearError } = useHttpClient();
    const [modalShow, setModalShow] = useState(false);
    const [modalAnswerShow, setModalAnswerShow] = useState(false);
    const [filteredSelect, setFilteredSelect] = useState(false);

    const selected = props.fieldSelect ? props.fieldSelect
    : "";
    const selected1 = props.formInformation ? props.formInformation.fieldSelect
    : "";
    useEffect(() => {
        let selectArray = [];
        if (props.formInformation) {
            if (props.formInformation.fieldSelect){
                selectArray = props.formInformation.fieldSelect
                .split(";")
                .map((item) => ({ value: item, text: item }));

            }
            
        }
        setFilteredSelect(selectArray);
    }, [selected, selected1]);

    const handleAnswered = async () => {
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_API_URL}/api/stands/${props.standName}/${props._id}/cardformanswer`,
                "POST",
                undefined,
                {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + auth.token,
                }
            );

            if (responseData.interested === "not-exist") {
                setModalShow(true);
            }else{
                setModalAnswerShow(true);
            }
        } catch (err) {}
        return;
    };

    return (
        <React.Fragment>
        {props.formInformation && (
        <Formik
                initialValues={{
                    singleQuestionAnswer: null,
                }}
                onSubmit={async (values, {resetForm}) => {
                    clearError();
                    try {
                        const responseData = await sendRequest(
                            `${process.env.REACT_APP_API_URL}/api/stands/${props.standName}/${props._id}/cardformsubmit`,
                            "POST",
                            JSON.stringify({
                                singleQuestionAnswer:
                                    values.singleQuestionAnswer,
                            }),
                            {
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + auth.token,
                            }
                        );

                        if (responseData.interested === "sent") {
                            resetForm({values:''});
                            setModalShow(false);
                            setModalAnswerShow(true);
                        }
                    } catch (err) {}
                }}
            >
                {() => (
                    <React.Fragment>
                    <GenericModal
                        title={props.formInformation.fieldDescription}
                        nonError={true}
                        buttonless={true}
                        show={modalShow}
                        setModalShow={setModalShow}
                    >
                        <Form className="lottery-update-form">
                            <div>
                                {props.formInformation && props.formInformation.fieldAs === "text" && (
                                    <FormInputComponent
                                        fieldName={"singleQuestionAnswer"}
                                        fieldPlaceholder={
                                            'Escriba su respuesta...'
                                        }
                                        className={"pb-4"}
                                    />
                                )}

                                {props.formInformation && props.formInformation.fieldAs === "select" && (
                                    <FormInputComponent
                                        fieldAs={"select"}
                                        fieldType={"select"}
                                        fieldName={"singleQuestionAnswer"}
                                        fieldSelect={filteredSelect}
                                        className={"pb-4"}
                                    />
                                )}

                                <Button
                                    className=""
                                    type="submit"
                                    status="active"
                                > 
                                <BsFillArrowRightCircleFill className="backend-imageButton__icon" />
                                    Enviar
                                </Button>
                            </div>
                        </Form>
                    </GenericModal>
                    <GenericModal
                        title="Muchas gracias por responder su pregunta"
                        nonError={true}
                        buttonless={true}
                        show={modalAnswerShow}
                        setModalShow={setModalAnswerShow}
                    >
                    </GenericModal>
                    </React.Fragment>
                )}
            </Formik>
            )}
        <div
            className={`${
                props.index === 0 ? "imagesola-stand-card--firstchild" : ""
            } ${
                props.preview ? "imagesola-stand-card--preview" : "imagesola-stand-card"
            }`}
        >
        {props.buttonType==="Action" && (
                    <button
                        className={`imagesola-stand-card__img${props.preview ? "--preview" : ""}`}
                        onClick={() => handleAnswered()}
                    >
                        <img
                            className={`imagesola-stand-card__img${props.preview ? "--preview" : ""}`}
                            src={
                                props.imagePreview ||
                                `${process.env.REACT_APP_API_URL}/${props.image}`
                            }
                            alt=""
                        ></img>
                    </button>
                )}
                {props.buttonType==="Url" && props.buttonLink && (
                        <a
                            className={`imagesola-stand-card__img${props.preview ? "--preview" : ""}`}
                            href={`//${props.buttonLink}`}
                            target="_blank"
                        >
                            <img
                                className={`imagesola-stand-card__img${props.preview ? "--preview" : ""}`}
                                src={
                                    props.imagePreview ||
                                    `${process.env.REACT_APP_API_URL}/${props.image}`
                                }
                                alt=""
                            ></img>
                        </a>
                    )}
                    {(props.buttonType==="None" || props.buttonType==="") && (
                            <img
                                className={`imagesola-stand-card__img${props.preview ? "--preview" : ""}`}
                                src={
                                    props.imagePreview ||
                                    `${process.env.REACT_APP_API_URL}/${props.image}`
                                }
                                alt=""
                            ></img>
                       
                    )}
                    
            
        </div>
        </React.Fragment>
    );
    
};

export default ImageStandCard;
