import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import "./main.css";

import { AuthContext } from "./shared/context/AuthContext";
import { LoadingContext } from "./shared/context/LoadingContext";
import { StandNavContext } from "./shared/context/StandNavContext";
import { useAuth } from "./shared/hooks/auth-hook";

import ScrollToTop from "./shared/helpers/ScrollToTop";
import LoadingSpinner from "./shared/components/UIElements/LoadingSpinner";

import Routes from "./routes/routes";

const App = () => {
    const { token, login, logout, authLoading, userId, loggedUserData } = useAuth();
    const [contextIsLoading, setContextIsLoading] = useState(false);
    const [renderIsLoading, setRenderIsLoading] = React.useState(true);

    const handleLoading = () => {
        setRenderIsLoading(false);
    };

    useEffect(() => {
        window.addEventListener("load", handleLoading);
        return () => window.removeEventListener("load", handleLoading);
    }, [renderIsLoading]);

    const [standNavContext, setStandNavContext] = useState(undefined);
    return (
        <AuthContext.Provider
            value={{
                isLoggedIn: !!token,
                token: token,
                userId: userId,
                authLoading: authLoading,
                loggedUserData: loggedUserData,
                login: login,
                logout: logout,
            }}
        >
            <StandNavContext.Provider
                value={{ standNavContext, setStandNavContext }}
            >
                <LoadingContext.Provider
                    value={{
                        contextIsLoading,
                        setContextIsLoading,
                    }}
                >
                    {(contextIsLoading || renderIsLoading) && (
                        <LoadingSpinner asOverlay />
                    )}
                    <Router>
                        <ScrollToTop />
                        <Routes />
                    </Router>
                </LoadingContext.Provider>
            </StandNavContext.Provider>
        </AuthContext.Provider>
    );
};

export default App;
