import React, { useContext } from "react";

import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/AuthContext";
import Button from "../../../shared/components/FormElements/Button";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import { BsQuestionCircle, BsFillArrowUpCircleFill } from "react-icons/bs";


import "./StandsBulkUpload.css";
const HelpSchema = Yup.object().shape({
    csv: Yup.mixed().required("Requerido"),
});

const BackendStandsBulkUpload = (props) => {
    const auth = useContext(AuthContext);
    const { sendRequest } = useHttpClient();

    return (
        <React.Fragment>
            <Formik
                initialValues={{
                    csv: "",
                }}
                validateOnMount
                validationSchema={HelpSchema}
                handleChange
                onSubmit={async (values) => {
                    try {
                        const formData = new FormData();
                        formData.append("csv", values.csv);
                        const responseData = await sendRequest(
                            `${process.env.REACT_APP_API_URL}/api/stands/createBulkStands`,
                            "POST",
                            formData,
                            {
                                Authorization: "Bearer " + auth.token,
                            }
                        );
                        props.handleShowModal(
                            "errors",
                            responseData.insertionErrors
                        );
                    } catch (err) {}
                }}
            >
                {(formProps) => (
                    <Form
                        className={`analytics-form ${
                            !props.isUsersBulk && "analytics-form--disabled"
                        }`}
                    >
                    <div className="bulk-upload-forms__top-container-purple">
                        
                        <div className="analytics-form__title">
                            Importar .csv de stands y expositores asociados
                            <div
                            className={`bulk-upload-forms__tooltip-container ${
                                !props.isUsersBulk && "disabled"
                            }`}
                            onClick={() => props.handleShowModal("stands")}
                        >
                            <BsQuestionCircle className="bulk-upload-forms__tooltip-purple" />
                        </div>
                        </div>
                       
                    </div>
                    <div className="bulk-upload-forms__bottom-container">
                        <input
                            type="file"
                            name="csv"
                            className="analytics-form__input"
                            onChange={(event) => {
                                formProps.setFieldValue(
                                    "csv",
                                    event.target.files[0]
                                );
                            }}
                        />
                        <div className="bulk__import-container">
                        <Button
                             className="bulk__import-button-purple"
                            type="submit"
                            status={
                                    props.isUsersBulk ? "active" : "inactive"
                            }
                        >
                        <BsFillArrowUpCircleFill className="backend-imageButton__icon" />
                            Importar
                        </Button>
                        </div>
                    </div>
                    </Form>
                )}
            </Formik>
        </React.Fragment>
    );
};

export default BackendStandsBulkUpload;
