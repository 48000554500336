import React, { useContext , useState, useEffect} from "react";

import "./UsosQr.css";
import { useHttpClient } from "../../shared/hooks/http-hook";
import celularsimplestands from "../assets/celularstand.gif";
import usoqr from "../assets/usoqr.gif";
import analiticas from "../assets/analiticas.jpg";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import { AuthContext } from "../../shared/context/AuthContext";
import simpleStandQr from "../assets/simplestandqr2.png";
import BackendImageButton from "../../backend/home/components/BackendImageButton";
import Button2 from '../../backend/home/assets/Boton2.png';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const UsosQr = () => {

    const auth = useContext(AuthContext);
    const [loggedUser, setLoggedUser] = useState(null);
    const { sendRequest } = useHttpClient();

    useEffect(() => {
        setLoggedUser(auth.loggedUserData);
    }, [auth.loggedUserData]);

    useEffect(() => {
        setLoggedUser(auth.loggedUserData);
    }, [sendRequest, auth.loggedUserData]);

    return (
        <React.Fragment>
        <Swiper
        cssMode={true}
        navigation={true}
        pagination={true}
        mousewheel={true}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        className="mySwiper swiper-container"
      >
        <SwiperSlide>
        <div className="big-wrapper-card flex-container p-y-lg align-items-center">
        <div className="width-half-content cs-one width-half-v1 pt-md-10 pl-md-2  pt-sm-3">
                    <p className="m-t-0 swiper-title">SIMPLESTAND</p>
                    <p className="m-y swiper-text">
                    Es la solución que necesitas para digitalizar tu presencia de marca en eventos de negocios, <b>automatizar</b> la captación de clientes y <b>amplificar</b> tu mensaje más allá del evento.
                    </p>
                    {/* <h3 className="video-banner__title">Escaneá aca y mirá una demo</h3> */}
                    <img className="swiper-example-qr__img" src={simpleStandQr} alt="" />
                    <p className="swiper-escanea-text">Escaneá aquí,<br/>y mirá una DEMO</p>
        </div>
                
                {auth.isLoggedIn && loggedUser &&
                    (loggedUser.userType !== "user") && (
                        <React.Fragment>
                        <div className="p-t pr-md-2 home-page-big-outer">
                        <div className="qr-use__add-stand">
                            <BackendImageButton 
                                to="/backend"
                                title="Agregar Stand"
                                image={Button2}
                                icon="add"
                                />
                        </div>
                        </div>
                        </React.Fragment>
                    )}
                    {!auth.isLoggedIn && !loggedUser && (
                        <div className="p-t pr-md-2 home-page-big-outer display-sm-none">
                        <img className="img-responsive"
                            src={celularsimplestands}
                            alt="Simple Stand example" width="364" height="274" />
                        </div>
                    )}  

        </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="big-wrapper-card flex-container p-y-lg flex-direction-column-reverse-sm align-items-center">
                <div className="width-half-content cs-one width-half pt-md-10 pl-md-2  pt-sm-3">
                    <h3 className="m-t-0">Impulsa una estrategia mixta de promoción para tu stand:</h3>
                    <p className="m-y swiper-text">
                    Activa promociones y concursos integrados a través de un QR. <br/>
                    Conecta tu audiencia con tu equipo comercial.
                    </p>
                </div>
                <div className="p-t pr-md-2 home-page-big-outer">
                    <img className="img-responsive"
                            src={usoqr}
                            alt="Qr uses" width="364" height="274" />
                </div>
                
            </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="big-wrapper-card flex-container p-y-lg align-items-center">
                <div className="p-t pr-md-2 home-page-big-outer">
                    <img className="img-responsive"
                            src={analiticas}
                            alt="Simple Stand example" width="364" height="274" />
                </div>
                <div className="width-half-content cs-one width-half pt-md-10 pl-md-2  pt-sm-3">
                    <h3 className="m-t-0">Analíticas en tiempo real</h3>
                    <p className="m-y swiper-text">
                    Mide los resultados de tus estrategias. <br/>
                    Aprende y optimiza tu estrategia. <br/>
                    Descarga tu bases de datos y genera acciones de promoción.
                    </p>
                </div>
            </div>
        </SwiperSlide>
        
      </Swiper>
      <div className="home-page-big-outer display-sm-true">
                    <img className="img-responsive"
                            src={celularsimplestands}
                            alt="Simple Stand example" width="364" height="274" />
     </div>

     </React.Fragment>
    );
};

export default UsosQr;
