import React, { useContext } from "react";
import { FormInputComponent } from "../../shared/components/FormElements/GenericForm";
import { Formik, Form } from "formik";

import Button from "../../shared/components/FormElements/Button";
import { BsFillArrowRightCircleFill } from "react-icons/bs";

import "./GenericFormCard.css";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/AuthContext";

const GenericFormCard = (props) => {
    const auth = useContext(AuthContext);
    const { sendRequest, clearError } = useHttpClient();

    let initialValues = {
        freeFieldTitle: "",
        dropdownInfo: false,
    };

    return (
        <React.Fragment>
            <Formik
                initialValues={initialValues}
                onSubmit={async (values, { resetForm }) => {
                    clearError();
                    try {
                        const responseData = await sendRequest(
                            `${process.env.REACT_APP_API_URL}/api/stands/${props.standName}/${props._id}/cardformsubmit`,
                            "POST",
                            JSON.stringify({
                                pregunta1: values.pregunta1,
                                pregunta2: values.pregunta2,
                                pregunta3: values.pregunta3,
                                pregunta4: values.pregunta4,
                                pregunta5: values.pregunta5,
                                pregunta6: values.pregunta6,
                            }),
                            {
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + auth.token,
                            }
                        );

                        if (responseData.interested === "sent") {
                            //console.log(responseData.userId,responseData.token);
                            //console.log("mail enviado");
                            resetForm({values:''});
                            
                        }
                    } catch (err) {}
                }}
            >
                {() => (
                    <Form className="lottery-update-form">
                        <div
                            className={`default-stand-card ${
                                props.index === 0
                                    ? "default-stand-card--firstchild"
                                    : ""
                            } ${
                                props.preview
                                    ? "default-stand-card--preview"
                                    : ""
                            }`}
                        >
                            <div className="default-stand-card__title">
                                <span
                                    className="default-stand-card__title"
                                    style={{ color: props.cardTextColor }}
                                >
                                    {props.firstTitle}
                                </span>
                            </div>
                            <FormInputComponent
                                fieldType="text"
                                fieldName={`pregunta1`}
                                fieldDescription={
                                    "Por favor contanos desde dónde venís a la feria"
                                } // cambiar
                                fieldPlaceholder=""
                                className={"form-card__inputcomponent"}
                            />
                            <FormInputComponent
                                fieldType="select"
                                fieldAs="select"
                                fieldName={`pregunta2`}
                                fieldDescription={
                                    "Por favor, ¿podrías indicarnos tu género?"
                                } // cambiar
                                fieldSelect={[
                                    {
                                        value: "MASCULINO",
                                        text: "MASCULINO",
                                    },
                                    {
                                        value: "FEMENINO",
                                        text: "FEMENINO",
                                    },
                                    {
                                        value: "OTROS",
                                        text: "OTROS",
                                    },
                                ]} //cambiar
                                className={"form-card__inputcomponent"}
                                fieldPlaceholder=""
                            />
                            <FormInputComponent
                                fieldType="select"
                                fieldAs="select"
                                fieldName={`pregunta3`}
                                fieldDescription={
                                    "Por favor, indicarnos tu edad "
                                } // cambiar
                                fieldSelect={[
                                    {
                                        value: "Entre 15 y 19 años",
                                        text: "Entre 15 y 19 años",
                                    },
                                    {
                                        value: "Entre 20 y 29 años",
                                        text: "Entre 20 y 29 años",
                                    },
                                    {
                                        value: "Entre 30 y 39 años",
                                        text: "Entre 30 y 39 años",
                                    },
                                    {
                                        value: "Entre 40 y 49 años",
                                        text: "Entre 40 y 49 años",
                                    },
                                    {
                                        value: "Entre 50 y 59 años",
                                        text: "Entre 50 y 59 años",
                                    },
                                    {
                                        value: "60 años o mas",
                                        text: "60 años o mas",
                                    },
                                ]} //cambiar
                                className={"form-card__inputcomponent"}
                                fieldPlaceholder=""
                            />
                            <FormInputComponent
                                fieldType="select"
                                fieldAs="select"
                                fieldName={`pregunta4`}
                                fieldDescription={
                                    "¿Cómo te enteraste de CAMINOS & SABORES? "
                                } // cambiar
                                fieldSelect={[
                                    {
                                        value: "Avisos en radio",
                                        text: "Avisos en radio",
                                    },
                                    {
                                        value: "Redes sociales",
                                        text: "Redes sociales",
                                    },
                                    {
                                        value: "Cartel en Subtes",
                                        text: "Cartel en Subtes",
                                    },
                                    {
                                        value: "Cartel en vía pública",
                                        text: "Cartel en vía pública",
                                    },
                                ]} //cambiar
                                className={"form-card__inputcomponent"}
                                fieldPlaceholder=""
                            />
                            <FormInputComponent
                                fieldType="select"
                                fieldAs="select"
                                multiple={true}
                                fieldName={`pregunta5`}
                                fieldDescription={
                                    "¿Cuáles son las 3 principales razones por las qué visitas CAMINOS & SABORES? "
                                } // cambiar
                                fieldSelect={[
                                    {
                                        value: "Relacionarme con colegas de la industria",
                                        text: "Relacionarme con colegas de la industria",
                                    },
                                    {
                                        value: "Visitar proveedores que ya conozco",
                                        text: "Visitar proveedores que ya conozco",
                                    },
                                    {
                                        value: "Para ver tendencias en ferias",
                                        text: "Para ver tendencias en ferias",
                                    },
                                    {
                                        value: "Descubrir nuevos productos",
                                        text: "Descubrir nuevos productos",
                                    },
                                ]} //cambiar
                                className={"form-card__inputcomponent"}
                                fieldPlaceholder=""
                            />
                            <FormInputComponent
                                fieldType="text"
                                fieldName={`pregunta6`}
                                fieldDescription={
                                    "¿Compraste productos en ferias? ¿Cuáles? "
                                } // cambiar
                                fieldPlaceholder=""
                                className={"form-card__inputcomponent"}
                            />
                            <Button
                                className=""
                                type="submit"
                                status="active"
                            >
                            <BsFillArrowRightCircleFill className="backend-imageButton__icon" />
                                Enviar Información
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </React.Fragment>
    );
};

export default GenericFormCard;
