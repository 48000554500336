import React from "react";
import { Link } from "react-router-dom";

import Button from "../FormElements/Button";
import "./Footer.css";
import iconoPoweredBy from "../../assets/btbox.png";

const Footer = (props) => {

    return (
        <React.Fragment>
            <div className="footer">
                <div className="footer-block">
                        
                        <div className="footer--mobile-margin">
                            <Link
                                to=""
                                className="footer-content__nav-link footer-content__nav-link--small footer--right-margin"
                            >
                                Políticas y Garantías
                            </Link>
                            <Link
                                to=""
                                className="footer-content__nav-link footer-content__nav-link--small"
                            >
                                Términos y Condiciones
                            </Link>
                        </div>
                        
                </div>
                <div className="footer__disclaimer">
                <div className="footer__disclaimer-text">© FULLEXY  </div>
                </div>

            </div>
        </React.Fragment>
    );
};

export default Footer;
