import React  from "react";
import { Link } from "react-router-dom";

import "./LotteryList.css";



const LotteryList = (props) => {

  if (props.user) {
  return (
    <React.Fragment>

    <div className="lottery-list__container">
      <table className="lottery-list__container-table">
        <thead>
          <tr>
            <th className="lottery-list__firstchild">Fecha</th>
            <th>Nombre</th>
            <th>Ganadores</th>
            <th className="lottery-list__lastchild">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {props.lotteryslist.map((item,index) => (
            
            <tr key={index}>
            
              <td>{item.fecha}</td>
              <td>{item.titleName}</td>
              <td>
                  <div>
                    <label>
                      <b>Ganadores</b> {item.winners}
                    </label>
                  </div>
                  <div>
                    <label>
                      <b>Suplentes</b> {item.backups}
                    </label>
                  </div>
              </td>
              <td>
                <Link
                    to={`../../../lottery/${item.id}`}
                >
                  Ver Detalle Sorteo
                </Link>
              </td>
            </tr>
          ))}
          
        </tbody>
      </table>
    </div>
    </React.Fragment>
  );}else {
    return null;
}
};

export default LotteryList;
