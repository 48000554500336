import React from "react";
import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";

import "./CarouselStandCard.css";

const CarouselStandCard = (props) => {

    const [index, setIndex] = useState(0);
  
    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };
    
    return (
        <div
            className={`carousel-stand-card carousel-stand-card--${
                props.preview ? "preview" : "grid"
            }`}
        >

    <Carousel activeIndex={index} onSelect={handleSelect} indicators={true} className="product-image-carrusel" controls={false}>
        
        {props.imageArray.map((item, index) => (
          <Carousel.Item key={index} interval={4500}><img src={`${process.env.REACT_APP_API_URL}/${item}`} className="d-block w-100" alt={props.firstTitle} /> 
          <Carousel.Caption>
                            <div className="carousel-stand-card__title">
                                <h3
                                    className="carousel-stand-card__title--yellow"
                                    style={{ color: props.cardTextColor }}
                                >
                                    {props.firstTitle}
                                </h3>
                            </div>
                            <p
                                className="carousel-stand-card__description"
                                style={{ color: props.cardTextColor }}
                            >
                                {props.description}
                            </p>
        </Carousel.Caption> 
          </Carousel.Item>
        ))}
    </Carousel>
   
        
        </div>
    );
};

export default CarouselStandCard;
