import React, { useState, useContext, useEffect, useCallback } from "react";

import LotteryList from "../components/LotteryList";
import LotteryModal from "../components/LotteryModal";
import { useParams } from "react-router-dom";
import BannerStands from '../../stands/assets/Banner5.png';
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/AuthContext";
import Button from "../../../shared/components/FormElements/Button";
import "./Lottery.css";
import { BsFillArrowLeftCircleFill, BsFillPlusCircleFill } from "react-icons/bs";

const Lottery = () => {
    const auth = useContext(AuthContext);
    const standName = useParams().standName;
    const { isLoading, sendRequest } = useHttpClient();
    const [showLotteryModal, setShowLotteryModal] = useState(false);
    const [loadedLotterys, setLoadedLotterys] = useState();
    const [loggedUser, setLoggedUser] = useState(null);
    const [lotteryrefresh, setLotterysRefresh] = useState(false);
    const [loadedStands, setLoadedStands] = useState();

    const handleLotteryCreate = useCallback(
        () => setShowLotteryModal(!showLotteryModal),
        [showLotteryModal]
    );
    useEffect(() => {
        const fetchStands = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_API_URL}/api/stands/`,
                    "GET",
                    null,
                    { Authorization: "Bearer " + auth.token }
                );
                setLoadedStands(responseData.stands);
            } catch (err) {}
        };
        fetchStands();
    }, [sendRequest, auth.token]);

    useEffect(() => {
        const fetchLotterys = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_API_URL}/api/lottery/`,
                    "GET",
                    null,
                    { Authorization: "Bearer " + auth.token }
                );
                setLoadedLotterys(responseData.lotterys);
                setLotterysRefresh(false);
            } catch (err) {}
        };
        fetchLotterys();
    }, [sendRequest, standName, lotteryrefresh, auth.token]);

    useEffect(() => {
        const fetchUser = async () => {
            if (auth.userId) {
                try {
                    const responseData = await sendRequest(
                        `${process.env.REACT_APP_API_URL}/api/users/${auth.userId}`,
                        "GET",
                        null,
                        { Authorization: "Bearer " + auth.token }
                    );
                    setLoggedUser(responseData.user);
                } catch (err) {}
            } else {
                setLoggedUser(null);
            }
        };
        fetchUser();
    }, [auth.login, auth.logout, auth.userId, auth.token, sendRequest]);

    return (
        <React.Fragment>
            <LotteryModal
                    show={showLotteryModal}
                    onHide={handleLotteryCreate}
                    stand={standName}
                    refresh={setLotterysRefresh}
                    stands={loadedStands}
            />
            <img src={BannerStands} width="100%" alt="" />
            <div className="generic-form__button-block"> 
                                <Button
                                    to="/backend/stands"
                                    className="stands-list__back-button"
                                >
                                    <BsFillArrowLeftCircleFill className="standlist-imageButton__iconblack" />
                                    Atrás
                                </Button>
            </div>
            <div className="lottery__header">
                <div className="lottery__header__button">
                    <Button status="active" onClick={handleLotteryCreate}>
                    <BsFillPlusCircleFill className="standlist-imageButton__iconblack" />
                        Generar Nuevo Sorteo
                    </Button>
                </div>
            </div>
            {!isLoading && loggedUser && loadedLotterys && (
                <LotteryList
                    lotteryslist={loadedLotterys}
                    user={loggedUser}
                    
                />
            )}
           
        </React.Fragment>
    );
};

export default Lottery;
