import React , { useContext , useState, useEffect, useRef, } from "react";
import { useParams, useHistory } from "react-router-dom";
import AuthModal from "../../user/components/AuthModal";
import { AuthContext } from "../../shared/context/AuthContext";
import { useHttpClient } from "../../shared/hooks/http-hook";
import HomePageForm from "../components/HomePageForm";
import HomePageFormText from "../components/HomePageFormText";
import ComoFunciona from "../components/ComoFunciona.jsx";
import PotenciaMarca from "../components/PotenciaMarca.jsx";
import UsosQr from "../components/UsosQr.jsx";
import Nosotros from "../components/Nosotros.jsx";
import Funcionalidades from "../components/Funcionalidades.jsx";
import QueresStand from "../components/QueresStand.jsx";
import Button from "../../shared/components/FormElements/Button";
import "./Home.css";
import { IoLogoWhatsapp } from "react-icons/io5";

import bannerVideo from "../assets/bannerVideo.mp4";

const Home = (props) => {
    const auth = useContext(AuthContext);
    let params = useParams().idpassword;
    const history = useHistory();
    const { sendRequest } = useHttpClient();
    const [loggedUser, setLoggedUser] = useState(null);
    const [showAuth, setShowAuth] = useState(false);
    const [modalType, setModalType] = useState(null);
    const isMuted = useRef(true);

    
    const handleCloseAuth = () => setShowAuth(false);



    useEffect(() => {
        setLoggedUser(auth.loggedUserData);
    }, [auth.loggedUserData]);
    useEffect(() => {
        if (params === 'x97123asd21429bHBMboer'){
            setShowAuth(true);
            setModalType("login");
            params='';
            parseInt(params);
            history.push(`/`);
        }
    },[params]);
    useEffect(() => {
        setLoggedUser(auth.loggedUserData);
    }, [sendRequest, auth.loggedUserData]);
    return (
        <React.Fragment>
        <AuthModal
                    show={showAuth}
                    onHide={handleCloseAuth}
                    setModalType={setModalType}
                    modalType={modalType}
                />
            <video playsInline autoPlay loop muted={isMuted.current} id="videoBanner">
                <source src={bannerVideo} type="video/mp4" />
            </video>
           
            <UsosQr />
          
            <PotenciaMarca />
            <Funcionalidades />
            <ComoFunciona />
            <Nosotros />
            <QueresStand />
           
             <div className="homeform-page__info--container">
                <HomePageFormText />
                <HomePageForm />
            </div> 
          
            <Button
                        className="main-button--whatsapp"
                        status="active"
                        type="submit"
                        href={`https://wa.me/5491123932179?text=Hola!%20Te%20escribo%20desde%20la%20plataforma%20de%20Simple%20Stand:...&type=phone_number&app_absent=0`}
                        target="_blank"
                    > <IoLogoWhatsapp className="main-icon--whatsapp"/>   
            </Button>
            
        </React.Fragment>
        
    );
};

export default Home;
