import { React} from "react";
import { IoCloseSharp } from "react-icons/io5";
import { Modal } from "react-bootstrap";
import "./MailModal.css";


const MailModal = (props) => {


    return (
        <Modal className="mail-update-modal" show={props.show}>
            <div className="mail-update-modal__header">
                <button
                    className="mail-update-modal__close"
                    onClick={props.onHide}
                >
                    <IoCloseSharp />
                </button>
                <div className="mail-update-modal__title">Mail enviado con Éxito</div>
            </div>
                
                <div className="mail-text">Muchas gracias por contactar a SimpleStand. Le responderemos a la brevedad.</div>
                
            
        </Modal>
        
    );
};

export default MailModal;
