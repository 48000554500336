import React from "react";

import "./PotenciaMarca.css";



const PotenciaMarca = () => {
    return (
        <div className="potencia-marca-banner">
            <p className="potencia-marca__title">Potencia tu marca con QR</p>
            <p className="potencia-marca__text">Crea tu stand digital con contenido dinámico, y aumentá tus oportunidades de negocios conociendo los intereses de tus futuros clientes</p>
        </div>
    );
};

export default PotenciaMarca;
