import React from "react";

import "./BackButton.css";
import Button from "../../../shared/components/FormElements/Button";

import { BsFillArrowLeftCircleFill } from "react-icons/bs";

const BackButton = (props) => {
    return (
        <div className="generic-form__button-block"> 
        <Button
            to="/backend"
            className="stands-list__back-button"
        >
            <BsFillArrowLeftCircleFill className="standlist-imageButton__iconblack" />
            Atrás
        </Button>
    </div>
    );
};

export default BackButton;
