import React from "react";

import "./Nosotros.css";

const Nosotros = () => {
    return (
        <div className="nosotros-container">
            <p className="nosotros-banner__title"> <i className="nosotros-banner__arrow-black"></i>Nosotros</p>

            <div className="nosotros-info__container">
                <div className="nosotros-video__container">
                <iframe className="nosotros-video__media" src='https://www.youtube.com/embed/gMIvc24au_k' allowFullScreen></iframe>
                </div>
                <div className="nosotros-info__text">
                <p>En SIMPLESTAND, <span>transformamos la forma en que las empresas se destacan en eventos</span> y conocen a sus futuros clientes.
                </p>
                <p>
                Si sos un expositor de un evento de negocios, <span>a través de códigos QR</span>, ofrecemos una <span>solución digital que impulsa tu presencia en eventos de negocios, promueve su marca con contenido dinámico y los conecta con su audiencia objetivo.</span> Con nuestras estrategias de marketing digital, captarás la atención y generarás bases de datos de potenciales clientes en tiempo real. 
                </p> 
                <p>
                SIMPLESTAND nace de la colaboración entre Wobax SRL, Onlife Soluciones - y es un spin-off de BTBOX, la exitosa plataforma de eventos y networking de negocios "todo en uno". Nuestro enfoque innovador se basa en digitalizar espacios de exhibición y stands, brindándote una solución directa para tus clientes finales y una herramienta complementaria para organizadores de eventos. 
                </p>
                <p className="nosotros-info__important">
                Descubre cómo SIMPLESTAND puede potenciar tu presencia en eventos y abrirte nuevas oportunidades en el mundo digital.
                </p>
                </div>
            </div>
           
           
        </div>
    );
};

export default Nosotros;
