import { createContext } from "react";

export const StandNavContext = createContext({
    standNavContext: {
        navColor: null,
        navButtonsColor: null,
        navIcon: null,
    },
    setStandNavContext: () => {},
});
