import { React, useRef, useEffect, useState, useContext } from "react";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { IoCloseSharp } from "react-icons/io5";
import { BsPencil , BsFillSaveFill , BsArrowLeftCircleFill , BsArrowCounterclockwise} from "react-icons/bs";

import { Modal } from "react-bootstrap";
import GenericModal from "../../shared/components/UIElements/GenericModal";
import { AuthContext } from "../../shared/context/AuthContext";
import * as Yup from "yup";
import "./UserUpdateModal.css";

import { useHttpClient } from "../../shared/hooks/http-hook";

import Button from "../../shared/components/FormElements/Button";
import Avatar from "../../shared/components/UIElements/Avatar";

const UpdateSchema = Yup.object().shape({
    firstName: Yup.string().max(24, "Demasiado largo").required("Requerido"),
    lastName: Yup.string().max(24, "Demasiado largo").required("Requerido"),
    password: Yup.string()
        .min(4, "Al menos 4 caracteres")
        .max(24, "Demasiado larga"),
    changePassword: Yup.string().when("password", {
        is: (val) => val && val.length > 0,
        then: Yup.string()
            .oneOf([Yup.ref("password")], "Ambas contraseñas deben coincidir")
            .required("Requerido"),
    }),
    image: Yup.mixed().nullable(true),
});

const UserUpdateModal = (props) => {
    const { sendRequest, error, clearError } = useHttpClient();
    const [previewImage, setPreviewImage] = useState();
    const [previewUrl, setPreviewUrl] = useState();
    const auth = useContext(AuthContext);
    useEffect(() => {
        if (!previewImage) {
            setPreviewUrl(null);
            return;
        }
        const fileReader = new FileReader();
        fileReader.onload = () => {
            setPreviewUrl(fileReader.result);
        };
        fileReader.readAsDataURL(previewImage);
    }, [previewImage]);

    const filePickerRef = useRef();

    let pickedImage;

    const pickedHandler = (event, formProps) => {
        if (event.target.files && event.target.files.length === 1) {
            pickedImage = event.target.files[0];
            setPreviewImage(pickedImage);
            formProps.setFieldValue("image", pickedImage);
        } else {
            setPreviewImage(null);
        }
    };

    const pickImageHandler = () => {
        filePickerRef.current.click();
    };

    const removeImageHandler = () => {
        filePickerRef.current.value = null;
        setPreviewImage(null);
    };

    const hideHandler = () => {
        return props.onHide();
    };

    return (
        <Modal className="user-update-modal" show={props.show}>
            <div className="user-update-modal__header">
                <button
                    className="user-update-modal__close"
                    onClick={props.onHide}
                >
                    <IoCloseSharp />
                </button>
                <div className="user-update-modal__title">Mis datos</div>
            </div>
            <Formik
                initialValues={{
                    firstName: props.firstName,
                    lastName: props.lastName,
                    image: null,
                    password: "",
                    changePassword: "",
                }}
                validationSchema={UpdateSchema}
                onSubmit={async (values) => {
                    clearError();
                    const formData = new FormData();
                    formData.append("firstName", values.firstName);
                    formData.append("lastName", values.lastName);
                    formData.append("password", values.password);
                    formData.append("image", values.image);
                    try {
                        const responseData = await sendRequest(
                            `${process.env.REACT_APP_API_URL}/api/users/${auth.userId}`,
                                    "PATCH",
                                    formData,
                            { Authorization: "Bearer " + auth.token }
                        );
                        auth.login(responseData);
                        hideHandler();
                    } catch (err) {}
                }}
            >
                {(formProps) => (
                    <Form className="user-update-form">
                        <GenericModal
                            message={error}
                            show={!!error}
                            error={true}
                            title={"Ocurrió un error"}
                            onClear={clearError}
                        />
                        <div className="user-update-form__content">

                            <div className="user-update-form__left-content">

                            <div className="user-update-form__input-block">
                                <div className="user-update-form__avatar-container">
                                    <Avatar
                                        image={
                                            previewUrl ||
                                            `${process.env.REACT_APP_API_URL}/${props.image}`
                                        }
                                        alt="default avatar"
                                        style={{
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                            width: "200px",
                                            height: "auto",
                                        }}
                                    ></Avatar>
                                    <div className="user-update-form__avatar-buttons-container">
                                        <div className="user-update-form__avatar-buttons">
                                            <input
                                                name="image"
                                                id="image"
                                                style={{ display: "none" }}
                                                ref={filePickerRef}
                                                type="file"
                                                accept=".jpg,.png,.jpeg"
                                                onChange={(event) => {
                                                    pickedHandler(
                                                        event,
                                                        formProps
                                                    );
                                                }}
                                            />
                                            <label htmlFor="image">
                                                <Button
                                                    status="active"
                                                    type="button"
                                                    className=""
                                                    onClick={pickImageHandler}
                                                >
                                                <BsPencil className="backend-imageButton__icon" />
                                                    Cambiar
                                                </Button>
                                            </label>
                                            <Button
                                                type="button"
                                                status={
                                                    !previewUrl && "inactive"
                                                }
                                                className="main-button--inverse"
                                                onClick={removeImageHandler}
                                            ><BsArrowCounterclockwise className="backend-imageButton__icon" />
                                                Reestablecer
                                            </Button>
                                        </div>
                                        
                                    </div>
                                </div>
                                <p>Tu foto nos ayuda a conocerte mejor</p> 
                            </div>
                            </div>

                            <div className="user-update-form__right-content">

                            <div className="user-update-form__input-block">
                                <div className="user-update__caption">
                                Usuario
                                </div>
                                <div className="user-update-form__email">
                                     {props.email}
                                </div>
                            </div>
                            <div className="user-update-form__input-block user-update-form__input-block--grid">
                                <div className="user-update-form__input-block user-update-form__input-block--column">
                                    <div className="user-update-form__error-placeholder user-update-form__error-placeholder--column">
                                        <ErrorMessage
                                            name="firstName"
                                            component="div"
                                            className="user-update-form__validation-error"
                                        />
                                    </div>
                                    <div className="user-update__caption">
                                    Nombre
                                    </div>
                                    <Field
                                        type="firstName"
                                        name="firstName"
                                        placeholder="Nombre"
                                        className="user-update-form__email"
                                    />
                                </div>
                                <div className="user-update-form__input-block user-update-form__input-block--column">
                                    <div className="user-update-form__error-placeholder user-update-form__error-placeholder--column">
                                        <ErrorMessage
                                            name="lastName"
                                            component="div"
                                            className="user-update-form__validation-error"
                                        />
                                    </div>
                                    <div className="user-update__caption">
                                    Apellido
                                    </div>
                                    <Field
                                        type="lastName"
                                        name="lastName"
                                        placeholder="Apellido"
                                        className="user-update-form__email"
                                    />
                                </div>
                            </div>
                            <div className="user-update-form__input-block user-update-form__input-block--grid">
                            <div className="user-update-form__input-block user-update-form__input-block--column">
                                   
                                    <div className="user-update__caption user-update__caption__violet">
                                            Cambio de Contraseña
                                    </div>
                                    <Field
                                        type="password"
                                        name="password"
                                        placeholder="Nueva Contraseña"
                                        className="user-update-form__email"
                                    />
                                     <div className="user-update-form__error-placeholder">
                                        <ErrorMessage
                                            name="password"
                                            component="div"
                                            className="user-update-form__validation-error"
                                        />
                                    </div>
                                </div>
                                <div className="user-update-form__input-block user-update-form__input-block--column">
                                    
                                    <div className="user-update__caption-invisible">
                                     -      
                                    </div>
                                    <Field
                                        type="password"
                                        name="changePassword"
                                        placeholder="Confirmar Contraseña"
                                        className="user-update-form__email"
                                    />
                                    <div className="user-update-form__error-placeholder">
                                        <ErrorMessage
                                            name="changePassword"
                                            component="div"
                                            className="user-update-form__validation-error"
                                        />
                                    </div>
                                </div>
                            </div>

                            
                        </div>
                        </div>
                        <div className="user-update-form__footer">
                            <Button
                                className="user-update__button-save main-button--squared__margin"
                                status={
                                    formProps.isValid && formProps.dirty
                                        ? "active"
                                        : "inactive"
                                }
                                type="submit"
                            >
                            <BsFillSaveFill className="backend-imageButton__icon" />
                                Guardar Cambios
                            </Button>
                            <Button
                                className="user-update__button-cancel"
                                status="inverse"
                                type="button"
                                onClick={hideHandler}
                            >
                            <BsArrowLeftCircleFill className="backend-imageButton__icon" />
                                Cancelar
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Modal>
        
    );
};

export default UserUpdateModal;
