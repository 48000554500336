import React, { useContext } from "react";

import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/AuthContext";
import Button from "../../../shared/components/FormElements/Button";

import { BsQuestionCircle , BsFillArrowUpCircleFill } from "react-icons/bs";


import { Formik, Form } from "formik";
import * as Yup from "yup";

import "./UsersBulkUpload.css";

const HelpSchema = Yup.object().shape({
    csv: Yup.mixed().required("Requerido"),
});
const BackendUsersBulkUpload = (props) => {
    const auth = useContext(AuthContext);
    const { sendRequest } = useHttpClient();

    return (
        <React.Fragment>
            <Formik
                initialValues={{
                    csv: "",
                }}
                validateOnMount
                validationSchema={HelpSchema}
                handleChange
                onSubmit={async (values) => {
                    try {
                        const formData = new FormData();
                        formData.append("csv", values.csv);
                        const responseData = await sendRequest(
                            `${process.env.REACT_APP_API_URL}/api/users/createBulkUsers`,
                            "POST",
                            formData,
                            {
                                Authorization: "Bearer " + auth.token,
                            }
                        );
                        props.handleShowModal(
                            "errors",
                            responseData.insertionErrors
                        );
                        props.setIsUsersBulk(true);
                    } catch (err) {}
                }}
            >
                {(formProps) => (
                    <Form className="analytics-form">
                        <div className="bulk-upload-forms__top-container">
                       

                        <div className="analytics-form__title">
                            Importar .csv de expositores
                            <div
                            className="bulk-upload-forms__tooltip-container"
                            onClick={() => props.handleShowModal("users")}
                            >
                            <BsQuestionCircle className="bulk-upload-forms__tooltip" />
                            </div>
                        </div>

                        </div>
                        
                        <div className="bulk-upload-forms__bottom-container">
                            <input
                                type="file"
                                name="csv"
                                className="analytics-form__input"
                                onChange={(event) => {
                                    formProps.setFieldValue(
                                        "csv",
                                        event.target.files[0]
                                    );
                                }}
                            />
                        <div className="bulk__import-container">
                        <Button
                            className="bulk__import-button"
                            type="submit"
                            status="active"
                        >
                        <BsFillArrowUpCircleFill className="backend-imageButton__icon" />
                            Importar
                        </Button>
                        </div>
                        </div>
                       
                    </Form>
                )}
            </Formik>
        </React.Fragment>
    );
};

export default BackendUsersBulkUpload;
