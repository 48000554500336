import React, { useState } from "react";

import BannerMasiveUpload from '../assets/Banner3.png'; 
import BackendUsersBulkUpload from "../components/UsersBulkUpload";
import BackendStandsBulkUpload from "../components/StandsBulkUpload";
import BackButton from "../../../shared/components/UIElements/BackButton";

import { AiOutlineArrowRight } from "react-icons/ai";

import GenericModal from "../../../shared/components/UIElements/GenericModal";
import "./BulkUpload.css";

const BackendBulkUpload = () => {
    const [isUsersBulk, setIsUsersBulk] = useState(false);
    const [modalType, setModalType] = useState("null");
    const [errors, setErrors] = useState(null);

    const [showModal, setShowModal] = useState(false);

    const handleShowModal = (componentType, jsonFile) => {
        setModalType(componentType);
        setErrors(jsonFile);
        setShowModal(true);
    };
    const handleCloseModal = () => setShowModal(false);

    return (
        <React.Fragment>
            <GenericModal
                show={showModal}
                error={false}
                title={modalType === "errors" ? "Reporte de importación" : "Ejemplo"}
                buttonless={false}
                closeFunction={handleCloseModal}
            >
                <div className="example-modal">
                    {modalType === "users" && (
                        <table className="error-table table table-striped">
                            <thead className="thead-dark">
                                <tr>
                                    <th>Nombre</th>
                                    <th>Apellido</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Juan</td>
                                    <td>Martin</td>
                                    <td>prueba100@gmail.com</td>
                                </tr>
                                <tr>
                                    <td>Pablo</td>
                                    <td>Mariano</td>
                                    <td>prueba200@gmail.com</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    {modalType === "stands" && (
                        <table className="error-table table table-striped">
                            <thead className="thead-dark">
                                <tr>
                                    <th>Stand</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>PruebaBulk</td>
                                    <td>prueba@gmail.com</td>
                                </tr>
                                <tr>
                                    <td>PruebaBulk2</td>
                                    <td>prueba2@gmail.com</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    {modalType === "errors" && errors && (
                        <table className="error-table table table-striped">
                            <thead className="thead-dark">
                                <tr>
                                    {Object.keys(errors[0]).map((header) => {
                                        return <th scope="col">{header}</th>;
                                    })}
                                </tr>
                            </thead>

                            <tbody>
                                {errors.map((content) => {
                                    return (
                                        <tr>
                                            {Object.values(content).map(
                                                (value) => {
                                                    return <td>{value}</td>;
                                                }
                                            )}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    )}
                </div>
            </GenericModal>
           
            <img src={BannerMasiveUpload} width="100%" alt="" />
            <BackButton/>
            <div className="bulk-upload-forms">
                <BackendUsersBulkUpload
                    setIsUsersBulk={setIsUsersBulk}
                    handleShowModal={handleShowModal}
                />
                <div className="bulk-upload-forms__arrow-container">
                    <AiOutlineArrowRight className="bulk-upload-forms__arrow" />
                </div>
                <BackendStandsBulkUpload
                    isUsersBulk={isUsersBulk}
                    handleShowModal={handleShowModal}
                />
            </div>
        </React.Fragment>
    );
};

export default BackendBulkUpload;
