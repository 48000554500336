import React from "react";

import { FormInputComponent } from "../../../../shared/components/FormElements/GenericForm";

const CustomQuestionsCardEdition = (props) => {
    return (
        <React.Fragment>
            <FormInputComponent
                fieldType="text"
                fieldName={`cards.${props.index}.firstTitle`}
                fieldDescription={"Primer Título"}
                fieldPlaceholder=""
            />
            <FormInputComponent
                fieldType="text"
                fieldName={`cards.${props.index}.cardTextColor`}
                fieldDescription={
                    "Color de texto: El color de texto elegido aplica para la descripción y el segundo título en la card."
                }
                colorPicker={true}
                colorPickerHandler={() =>
                    props.showHexPickerHandler(
                        `values.cards.${props.index}.cardTextColor`,
                        `cards.${props.index}.cardTextColor`
                    )
                }
                fieldPlaceholder="#000000"
            />
            <FormInputComponent
                fieldType="text"
                fieldName={`cards.${props.index}.buttonText`}
                fieldDescription="Texto de botón"
                fieldPlaceholder=""
            />
        </React.Fragment>
    );
};

export default CustomQuestionsCardEdition;
