import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import "./Pagination.css";

import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";

import UsersList from "../../../backend/analytic/components/UsersList";

function UserPagination({ itemsPerPage, items, ...props }) {
    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = items.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(items.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        setItemOffset(newOffset);
    };

    return (
        <>
            <UsersList
                userslist={currentItems}
                user={props.user}
                refresh={props.setUsersRefresh}
                query={props.query}
            />
            <div className={"pagination__container"}>
                <ReactPaginate
                    activeClassName={"item active "}
                    breakClassName={"item break-me "}
                    breakLabel={"..."}
                    containerClassName={"pagination"}
                    disabledClassName={"disabled-page"}
                    marginPagesDisplayed={2}
                    nextClassName={"item next "}
                    nextLabel={
                        <MdKeyboardArrowRight
                            style={{ fontSize: 18, width: 150 }}
                        />
                    }
                    pageClassName={"item pagination-page "}
                    previousClassName={"item previous"}
                    previousLabel={
                        <MdKeyboardArrowLeft
                            style={{ fontSize: 18, width: 150 }}
                        />
                    }
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={10}
                    pageCount={pageCount}
                    renderOnZeroPageCount={null}
                />
            </div>
        </>
    );
}

export default UserPagination;
