import React from "react";

import { FormInputComponent } from "../../../../shared/components/FormElements/GenericForm";

const MapStandCardEdition = (props) => {
    return (
        <React.Fragment>
            <div>
               
                <FormInputComponent
                    fieldType="text"
                    fieldName={`cards.${props.index}.latitude`}
                    fieldDescription="Latitude"
                    fieldPlaceholder=""
                />
                <FormInputComponent
                    fieldType="text"
                    fieldName={`cards.${props.index}.longitude`}
                    fieldDescription="Longitud"
                    fieldPlaceholder=""
                />
                <h4 className="mt-4">¿Cómo obtener tu latitud y longitud?</h4>
                <h4 className="mt-4">
                    Para obtener la latitud y longitud de tu ubicación, busca el
                    lugar requerido en Google Maps y hace click derecho en el
                    punto elegido para obtener el dato: Encontrarás una
                    numeraciones al principio separado por una coma: el primer
                    número hasta la coma es la latitud, el segundo número hasta
                    el final es la longitud.
                </h4>
                <img
                    className="mt-1 update-image__map"
                    src={props.mapimage}
                    alt=""
                ></img>
            </div>
        </React.Fragment>
    );
};

export default MapStandCardEdition;
