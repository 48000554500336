import React from "react";

import "./Funcionalidades.css";

// import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';

// import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, EffectCoverflow } from 'swiper/modules';

import photo1 from "../assets/icono1.png";
import photo2 from "../assets/icono2.png";
import photo3 from "../assets/icono3.png";
import photo4 from "../assets/icono4.png";
import photo5 from "../assets/icono5.png";
import photo6 from "../assets/icono6.png";
import photo7 from "../assets/icono7.png";
import photo8 from "../assets/icono8.png";
import photo9 from "../assets/icono9.png";
import photo10 from "../assets/icono10.png";

const Funcionalidades = () => {
    return (
        <div className="functionality-container">
         <p className="functionality__title"> <i className="functionality__arrow-white"></i>En nuestras soluciones encontrarás:</p>
        <div className="functionality-swiper-container">
        <Swiper
          modules={[Pagination, EffectCoverflow]}
          effect="coverflow"
          grabCursor="true"
          centeredSlides="true"
          spaceBetween={10}
          slidesPerView={1}
          breakpoints={{
            576: {
            slidesPerView: 2,
            },
            768: {
            slidesPerView: 4,
            },
        }}
          loop="true"
          pagination={{ dynamicBullets: true }}
        >
          <SwiperSlide>
            <img src={photo1} alt="pic1" className="photos" />
            <p className="functionality__text">QRs con contenido dinámicos ilimitados y look & feel del cliente, para potenciar tu presencia en el evento. Escaneos Ilimitados.</p>
          </SwiperSlide>
          <SwiperSlide>
            <img src={photo2} alt="pic2" className="photos" />
            <p className="functionality__text">Distintos Planes de contratación para customizar contenido:  videos, fotos, textos, cupones, agenda, encuestas.</p>
          </SwiperSlide>
          <SwiperSlide>
            <img src={photo3} alt="pic3" className="photos" />
            <p className="functionality__text">Distintas formas de generar engagement con tu audiencia:  Sistema de Sorteos y concursos incluido + Espacio lúdico: Sistema de votaciones.</p>
          </SwiperSlide>
          <SwiperSlide>
            <img src={photo4} alt="pic4" className="photos" />
            <p className="functionality__text">Posibilidad de personalización de Formularios de registro y preguntas de calificación de usuario atadas a contenido, para conocer mejor a tu audiencia.</p>
          </SwiperSlide>
          <SwiperSlide>
            <img src={photo5} alt="pic5" className="photos" />
            <p className="functionality__text">Botones ilimitados que redireccionan a Links / URLs para que puedas vincular tu espacio digital con tu marca institucional.</p>
          </SwiperSlide>
          <SwiperSlide>
            <img src={photo6} alt="pic5" className="photos" />
            <p className="functionality__text">Conexión con Whatsapp y MAIL representante comercial, para que puedas potenciar tu presencia comercial, mas allá de lo presencial.</p>
          </SwiperSlide>
          <SwiperSlide>
            <img src={photo7} alt="pic5" className="photos" />
            <p className="functionality__text">Versatilidad de la plataforma: Podrás usarla en formato plataforma web y full responsive para el celular.</p>
          </SwiperSlide>
          <SwiperSlide>
            <img src={photo8} alt="pic5" className="photos" />
            <p className="functionality__text">Links a Redes sociales: Linkedin, Twitter, IG, facebook.</p>
          </SwiperSlide>
          <SwiperSlide>
            <img src={photo10} alt="pic5" className="photos" />
            <p className="functionality__text">Exportación de tus bases de datos de visitas y participantes de sorteos en formato Excel + Analíticas en tiempo real.</p>
          </SwiperSlide>
        </Swiper>
        </div>
       
      </div>
    );
};

export default Funcionalidades;
