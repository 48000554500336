import React from "react";

import "./TitleTextStandCard.css";
import Button from "../../shared/components/FormElements/Button";
import { BsFillArrowRightCircleFill } from "react-icons/bs";

const TitleTextStandCard = (props) => {
    

    return (
        <div
            className={`titletext-stand-card ${
                props.index === 0 ? "titletext-stand-card--firstchild" : ""
            } ${
                props.preview ? "titletext-stand-card--preview" : ""
            }`}
        >
        <div>
                <span
                    className="titletext-stand-card__title"
                    style={{ color: props.cardTextColor }}
                >
                    {props.firstTitle}{" "}
                </span>
            </div>
            {props.description && (
                        <div
                            className="titletext-stand-card__description"
                            style={{ color: props.cardTextColor }}
                        >
                            {props.description}
                        </div>
                    )}
            {props.buttonLink && (
                <div className="mt-4 text-center">
                    <Button
                        className=""
                        type="submit"
                        style={{
                            backgroundColor: props.hexColor,
                            color: props.buttonTextColor,
                            textAlign:"center",
                        }}
                        href={`//${props.buttonLink}`}
                        target="_blank"
                    ><BsFillArrowRightCircleFill className="backend-imageButton__icon" />
                        {props.buttonText}
                    </Button>
                </div>
        )}
        </div>
    );
};

export default TitleTextStandCard;
