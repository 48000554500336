import React  from "react";
import { Link } from "react-router-dom";

import "./BackendImageButton.css";

import { BsPencil , BsFillPlusCircleFill , BsFillPersonLinesFill , BsUpload } from "react-icons/bs";


const BackendImageButton = (props) => {

  return (
    <React.Fragment>
        <Link className="backend-imageButton__link" to={props.to}>
          <div className="backend-imageButton__container">
            <img width="100%" src={props.image} alt={props.title}/>
            <p className="backend-imageButton__text">
            {props.icon == 'pencil' && <BsPencil className="backend-imageButton__icon" />}
            {props.icon == 'add' && <BsFillPlusCircleFill className="backend-imageButton__icon" />}
            {props.icon == 'adminusers' && <BsFillPersonLinesFill className="backend-imageButton__icon" />}
            {props.icon == 'masive' && <BsUpload className="backend-imageButton__icon" />}
            {props.title}
            </p>
          </div>
        </Link>
    </React.Fragment>
  )
};

export default BackendImageButton;
