import React, { useContext } from "react";

import { AuthContext } from "../../../shared/context/AuthContext";
import BackendImageButton from "../components/BackendImageButton";

import BannerBackend from '../assets/Banner1.png'; 
import Button1 from '../assets/Boton1.png';
import Button2 from '../assets/Boton2.png';
import Button3 from '../assets/Boton3.png';
import Button4 from '../assets/Boton4.png';

import "./Home.css";

const BackendHome = () => {
    const auth = useContext(AuthContext);

    return (
        <React.Fragment>
            <img src={BannerBackend} width="100%" alt="" />
            <div className="backend-home__container">

                <BackendImageButton 
                    to="/backend/stands"
                    title="Ver / Editar Stands"
                    image={Button1}
                    icon="pencil"
                />
                {auth.loggedUserData.userType !== "expositor" && (
                    <React.Fragment>
                        <BackendImageButton 
                        to="/backend/stands/new"
                        title="Agregar Stand"
                        image={Button2}
                        icon="add"
                        />
                        <BackendImageButton 
                        to="/backend/users"
                        title="Admin de Usuarios"
                        image={Button3}
                        icon="adminusers"
                        />
                           <BackendImageButton 
                        to="/backend/bulk"
                        title="Carga Masiva"
                        image={Button4}
                        icon="masive"
                        />
                    
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
    );
};

export default BackendHome;
