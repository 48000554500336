import { React } from "react";
import Lottie from "lottie-react";
import qrloading from "../../assets/loadingqr.json";

import "./LoadingSpinner.css";

const LoadingSpinner = (props) => {
    return (
        <div className="container-lottie">
             <Lottie animationData={qrloading} loop={true} />
        </div>
    );
};

export default LoadingSpinner;
