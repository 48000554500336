import React, { useState, useContext, useEffect, useCallback } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { IoCloseSharp } from "react-icons/io5";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import "./CreateUserModal.css";

import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/AuthContext";
import Button from "../../../shared/components/FormElements/Button";
import { FormInputComponent } from "../../../shared/components/FormElements/GenericForm";

const SignupSchema = Yup.object().shape({
    firstName: Yup.string().max(24, "Demasiado largo").required("Requerido"),
    lastName: Yup.string().max(24, "Demasiado largo").required("Requerido"),
    email: Yup.string().email("Correo inválido").required("Requerido"),
    password: Yup.string()
        .min(6, "Al menos 6 caracteres")
        .max(24, "Demasiado larga")
        .required("Requerida"),
});

const CreateModal = (props) => {
    const auth = useContext(AuthContext);
    const { sendRequest, error, clearError } = useHttpClient();
    const [adminUsers, setAdminUsers] = useState('');

    const getAdminUsers = useCallback(async () => {
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_API_URL}/api/users/admin`
            );
            
            setAdminUsers(responseData.adminusers);
        } catch (err) {}
    }, [sendRequest]);

    useEffect(() => {
        getAdminUsers();
    }, [getAdminUsers]);
    
    return (
        
        <Modal className="auth-modal" show={props.show} onHide={props.onHide}>
            <div className="auth-modal__header">
                <button
                    onClick={props.redirect || props.onHide}
                    className="auth-modal__close"
                >
                    <IoCloseSharp />
                </button>
                <div className="auth-modal__title">Crear Expositor</div>
            </div>

            <Formik
                initialValues={{
                    firstName: "",
                    lastName: "",
                    email: "",
                    password: "",
                    confirmPassword: "",
                    termsConditions: "",
                    creator: [],
                }}
                validationSchema={SignupSchema}
                onSubmit={async (values, { setFieldValue, resetForm }) => {
                    clearError();
                    let variablecreator = "";
                    if (auth.loggedUserData.userType === "superadmin") {
                        if (values.creator.length > 0) {
                            variablecreator = values.creator;
                        } else {
                            variablecreator = auth.userId;
                        }
                    } else {
                        variablecreator = auth.userId;
                    }

                    try {
                        const responseData = await sendRequest(
                            `${process.env.REACT_APP_API_URL}/api/users/createUser`,
                            "POST",
                            JSON.stringify({
                                firstName: values.firstName,
                                lastName: values.lastName,
                                email: values.email,
                                password: values.password,
                                termsConditions: true,
                                creator: variablecreator,
                            }),
                            {
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + auth.token,
                            }
                        );
                        props.onHide();
                        props.refresh(true);
                    } catch (err) {}
                }}
            >
                {({ isValid, dirty }) => (
                    <div>
                        <Form className="auth-form">
                            {error && (
                                <div className="auth-form__api-error-placeholder">
                                    <div className="auth-form__api-error">
                                        {error}
                                    </div>
                                </div>
                            )}
                            <div className="auth-form__content">
                                <div className="auth-form__input-block expo-auth-form__input-block--grid">
                                    <div className="auth-form__input-block auth-form__input-block--column">
                                        <div className="auth-form__error-placeholder auth-form__error-placeholder--column">
                                            <ErrorMessage
                                                name="firstName"
                                                component="div"
                                                className="auth-form__validation-error"
                                            />
                                        </div>
                                        <Field
                                            type="firstName"
                                            name="firstName"
                                            placeholder="Nombre"
                                            className="user-update-form__email"
                                        />
                                    </div>
                                    <div className="auth-form__input-block auth-form__input-block--column">
                                        <div className="auth-form__error-placeholder auth-form__error-placeholder--column">
                                            <ErrorMessage
                                                name="lastName"
                                                component="div"
                                                className="auth-form__validation-error"
                                            />
                                        </div>
                                        <Field
                                            type="lastName"
                                            name="lastName"
                                            placeholder="Apellido"
                                            className="user-update-form__email"
                                        />
                                    </div>
                                </div>

                                <div className="auth-form__input-block">
                                    <div className="auth-form__error-placeholder">
                                        <ErrorMessage
                                            name="email"
                                            component="div"
                                            className="auth-form__validation-error"
                                        />
                                    </div>
                                    <Field
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        className="user-update-form__email"
                                    />
                                </div>
                                <div className="auth-form__creator">
                                    {auth.loggedUserData.userType ===
                                        "superadmin" &&
                                        adminUsers && (
                                            <FormInputComponent
                                                formInputLayout="column"
                                                fieldAs="select"
                                                fieldSelect={adminUsers}
                                                fieldName="creator"
                                                fieldPlaceholder="Seleccione el Owner"
                                            />
                                        )}
                                    {auth.loggedUserData.userType === "admin" &&
                                        adminUsers && (
                                            <FormInputComponent
                                                formInputLayout="column"
                                                fieldAs="select"
                                                fieldSelect={adminUsers}
                                                fieldName="creator"
                                                disabled={true}
                                                fieldPlaceholder="Seleccione el Owner"
                                            />
                                        )}
                                </div>
                                <div className="auth-form__input-block">
                                    <div className="auth-form__error-placeholder">
                                        <ErrorMessage
                                            name="password"
                                            component="div"
                                            className="auth-form__validation-error"
                                        />
                                    </div>
                                    <Field
                                        type="password"
                                        name="password"
                                        placeholder="Contraseña"
                                        className="user-update-form__email"
                                    />
                                </div>
                            </div>
                            <div className="generic-modal__footer">
                                <Button
                                    type="submit"
                                    className=""
                                    status="active"
                                >
                                <BsFillArrowRightCircleFill className="backend-imageButton__icon" />
                                Crear
                                </Button>
                            </div>
                           
                        </Form>
                    </div>
                )}
            </Formik>
        </Modal>
    );
};

export default CreateModal;
