import React from "react";

import Button from "../../shared/components/FormElements/Button";
import "./QueresStand.css";



const QueresStand = () => {
    return (
        <div className="queres-stand-banner">
            <p className="queres-stand__title">¿Querés tener tu SIMPLESTAND?</p>
            <div className="queres-stand__container">
            <Button
                className="queres-stand__main-button"
                status="active"
                type="submit"
                href="https://drive.google.com/file/d/1rIY1XGZxqCXm7IZmbg4D352k8EtyqGem/view?usp=sharing"
                target="_blank"
            >Conocer más   
            </Button>
            <Button
                className="queres-stand__main-button"
                status="active"
                type="submit"
                href={`https://wa.me/5491123932179?text=Hola!%20Te%20escribo%20desde%20la%20plataforma%20de%20Simple%20Stand:...&type=phone_number&app_absent=0`}
                target="_blank"
            >Contactar a un comercial   
            </Button>
            <p className="queres-stand__text">para obtener una cotización y completar el formulario</p>
           
           </div>
        </div>
    );
};

export default QueresStand;
